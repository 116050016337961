import styled from "styled-components";
import { Modal } from "antd";

export const StyledCreateProjectPage = styled.div`
  .title {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .create-table-btn {
    height: 15em;
    width: 15em;
    border: 1px dashed;
  }
`;

export const StyledCreateTableModal = styled(Modal)`
  .steps-content {
    margin-top: 2em;

    .basic-item,
    .normal-item,
    .sub-item {
      margin-top: 2em;
    }
  }
`;
