import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Modal, Input } from "antd";

class CreateProjectModal extends React.PureComponent {
  render() {
    return (
      <Modal
        title="项目基本信息"
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        onOk={() => {
          this.props.createProject(
            this.props.newProject,
            this.props.projectList,
            this.props.history
          );
        }}
      >
        <span style={{ color: "red", marginRight: 10 }}>*</span>
        项目名称：
        <Input
          onChange={this.props.changeProjectTitle}
          style={{ width: "100%", marginTop: "1em" }}
        />
      </Modal>
    );
  }
}

const STP = (state) => {
  return {
    visible: state.getIn(["project", "modal", "createProject"]),
    projectList: state.getIn(["project", "projectList"]),
    newProject: state.getIn(["project", "newProject"])
  };
};

const DTP = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(action.changeItem(["modal", "createProject"], false));
    },
    changeProjectTitle: (e) => {
      dispatch(action.changeItem(["newProject"], e.target.value));
    },
    createProject: (title, projectList, history) => {
      dispatch(action.createProject(title, projectList, history));
    }
  };
};

export default withRouter(connect(STP, DTP)(CreateProjectModal));
