import styled from "styled-components";
import { Modal } from "antd";

export const StyledQRcodeModal = styled(Modal)`
  .qrcode {
    margin-top: 2em;
    display: block;
    text-align: center;
  }
`;
