import React from "react";
import { action } from "store/Mark";
import { connect } from "react-redux";

import styled from "styled-components";
import Draggable from "react-draggable";
import { Button, Popconfirm, Progress, Modal } from "antd";

import ScoreCard from "./ScoreCard";
import ScoreDrawer from "./ScoreDrawer";
import MarkLayout from "layout/MarkLayout";

import { sum } from "utils/arr";

const StyledScorePage = styled.div`
  position: relative;

  .sheet {
    margin-top: 1em;
    padding: 1em;
    background: #fff;
    box-shadow: 0px 0px 2px 0px #bdbdbd;
  }

  .affix {
    position: fixed;
    background: #fff;
    border-radius: 50%;
    bottom: 1em;
    right: 1em;
    z-index: 999;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    .progress {
      border-radius: 50%;
      margin-bottom: 15px;
      box-shadow: 0px 0px 5px 1px #d0d0d0;

      .ant-progress-inner {
        width: 100% !important;
        height: 100% !important;
        font-size: 14px !important;
        background: #fff;
      }
    }
  }
`;

/**
 * 评分表页面
 */
class ScorePage extends React.Component {
  componentDidMount() {
    this.props.loadAutoSave(this.props.currentTableInfo.get("id"));
  }

  render() {
    const { currentTableInfo, canLoadSave } = this.props;
    const remain = sum(
      ["basic", "normal", "sub"].map(
        (item) =>
          currentTableInfo.get(item).filter((i) => i.get("status", 1) !== 0)
            .size -
          currentTableInfo
            .get(item)
            .filter((i) => i.get("status", 1) !== 0)
            .filter((i) => i.get("value")).size
      )
    );
    const total = sum(
      ["basic", "normal", "sub"].map(
        (item) =>
          currentTableInfo.get(item).filter((i) => i.get("status", 1) !== 0)
            .size
      )
    );

    const percent = 100 - parseInt((remain / total) * 100);

    return (
      <MarkLayout
        title={currentTableInfo ? currentTableInfo.get("title") : ""}
        content={
          <StyledScorePage>
            <b>备注：请确保当前页的分值合计为100</b>
            {this.props.projectInfo.get("status") > 1 ? (
              <span>
                <br />
                蓝色值为上一轮评分的均值，默认值为您上轮的评分
              </span>
            ) : null}

            <Modal
              visible={canLoadSave}
              title="是否加载自动保存内容"
              onOk={() => {
                this.props.loadAutoSave(this.props.currentTableInfo.get("id"));
              }}
              onCancel={() => {
                this.props.changeItem(["canLoadSave"], false);
              }}
            >
              <p>检测到有自动保存的内容，是否加载？</p>
            </Modal>

            {/* 评分卡的按钮 */}
            <Draggable
              defaultClassName="affix"
              bounds={{
                top: -document.body.clientHeight + 100,
                left: -document.body.clientWidth + 85,
                right: 0,
                bottom: 0
              }}
            >
              <Progress
                width={68}
                type="circle"
                percent={percent}
                onClick={this.props.showScoreDrawer}
                format={(percent) => (percent === 100 ? "已完成" : "导航卡")}
              />
            </Draggable>
            <ScoreDrawer />

            <Popconfirm
              placement="bottomRight"
              title="确定要返回任务列表吗？"
              onConfirm={() => {
                this.props.changeCurrentPage(1);
              }}
            >
              <Button
                type="primary"
                size="small"
                style={{ position: "fixed", top: 0, right: 0, zIndex: 1 }}
              >
                返回任务列表
              </Button>
            </Popconfirm>

            {/* 评分表正式内容 */}
            <div className="sheet">
              <ScoreCard />
            </div>
          </StyledScorePage>
        }
      />
    );
  }
}

const STP = (state) => {
  return {
    currentTableInfo: state.getIn(["mark", "currentTableInfo"]),
    scoreCard: state.getIn(["mark", "scoreCard"]),
    canLoadSave: state.getIn(["mark", "canLoadSave"]),
    projectInfo: state.getIn(["mark", "projectInfo"])
  };
};

const DTP = (dispatch) => {
  return {
    loadScoreTable: (projectId, tableId, userId) => {
      dispatch(action.loadScoreTable(projectId, tableId, userId));
    },
    changeCurrentPage: (target) => {
      dispatch(action.changeItem(["currentPage"], target));
    },
    showScoreDrawer: () => {
      dispatch(action.changeItem(["modal", "scoreDrawer"], true));
    },
    loadAutoSave: (tableId) => {
      dispatch(action.loadAutoSave(tableId));
    },
    changeItem: (target, value) => {
      dispatch(action.changeItem(target, value));
    }
  };
};

export default connect(STP, DTP)(ScorePage);
