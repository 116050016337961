import React from "react";
import { Tag } from "antd";

export const getProjectStatusTag = (status) => {
  switch (status) {
    case 0:
      return <Tag>未发布</Tag>;
    case 1:
      return <Tag color="orange">第一轮</Tag>;
    case 2:
      return <Tag color="blue">第二轮</Tag>;
    case 5:
      return <Tag color="green">已完成</Tag>;
    default:
      break;
  }
};
