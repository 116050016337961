import * as constant from "./constant";

export const changeModal = (value) => ({
  type: constant.CHANGE_MODAL,
  value: value
});

export const changeItem = (target, value) => ({
  type: constant.CHANGE_ITEM,
  target: target,
  value: value
});

export const changeLoading = () => ({
  type: constant.CHANGE_LOADING
});

export const changeDataLoading = () => ({
  type: constant.CHANGE_DATA_LOADING
});
