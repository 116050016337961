import { message } from "antd";
import { API } from "utils/api";
import { Post } from "utils/request";
import * as constant from "./constant";

export const changeItem = (target, value) => ({
  type: constant.CHANGE_ITEM,
  target: target,
  value: value
});

export const login = (data, history) => {
  return (dispatch) => {
    dispatch({
      type: constant.CHANGE_ITEM,
      target: ["loading"],
      value: true
    });
    Post(API.LOGIN, data)
      .then((res) => {
        localStorage.setItem("adminInfo", JSON.stringify(res));
        message.success("登录成功");
        history.push("/");
      })
      .catch((err) => {})
      .then(() => {
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["loading"],
          value: false
        });
      });
  };
};
