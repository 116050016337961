import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import { API } from "utils/api";

class ResultExportModal extends React.PureComponent {
  render() {
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.closeResultModal}
        title="导出结果"
      >
        <Button
          type="primary"
          onClick={() => {
            window.open(
              `${API.PROJECT_RESULT}/${
                this.props.currentProject
              }?rounds=1&type=excel&v=${new Date().getTime()}`,
              "_blank"
            );
          }}
        >
          第一轮
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 10 }}
          onClick={() => {
            window.open(
              `${API.PROJECT_RESULT}/${
                this.props.currentProject
              }?rounds=2&type=excel&v=${new Date().getTime()}`,
              "_blank"
            );
          }}
        >
          第二轮
        </Button>
      </Modal>
    );
  }
}

const STP = (state) => {
  return {
    visible: state.getIn(["project", "modal", "result"]),
    currentProject: state.getIn(["project", "currentSelect", "project"])
  };
};

const DTP = (dispatch) => {
  return {
    closeResultModal: () => {
      dispatch(action.changeItem(["modal", "result"], false));
      dispatch(
        action.changeItem(["project", "currentSelect", "project"], undefined)
      );
    }
  };
};

export default connect(STP, DTP)(ResultExportModal);
