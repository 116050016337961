import * as constant from "./constant";
import { constant as layoutConstant } from "store/Layout";
import { Get } from "utils/request";
import { API } from "utils/api";
import { fromJS } from "immutable";
import { Post } from "utils/request";
import { message } from "antd";
import { sum } from "utils/arr";

export const changeItem = (target, value) => ({
  type: constant.CHANGE_ITEM,
  target: target,
  value: value
});

export const changeScoreCard = (source) => ({
  type: constant.CHANGE_SCORE_CARD,
  source
});

export const currentUserIsScored = () => {
  return (dispatch) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      Get(`${API.SCORER}/${userId}`).then((res) => {
        // 校验通过
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["modal", "isLogin"],
          value: true
        });
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["userInfo"],
          value: fromJS(res)
        });
      });
    }
  };
};

export const submitUserInfo = (values, signature) => {
  return (dispatch) => {
    let data = values;
    data.signature = signature;

    Post(API.SCORER, data).then((res) => {
      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["userInfo"],
        value: fromJS(res)
      });

      localStorage.setItem("userId", res.id);
      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["currentPage"],
        value: 1
      });
    });
  };
};

export const loadProject = (projectId) => {
  return (dispatch) => {
    Get(
      `${API.PROJECT_BASE}/${projectId}?t=1&u=${localStorage.getItem("userId")}`
    ).then((res) => {
      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["projectInfo"],
        value: fromJS(res)
      });
    });
  };
};

export const loadScoreTable = (tableId, projectInfo) => {
  return (dispatch) => {
    dispatch({
      type: layoutConstant.CHANGE_ITEM,
      target: ["pageLoading"],
      value: true
    });

    Get(
      `${API.TABLE_BASE}/${tableId}?rounds=${projectInfo.get(
        "status"
      )}&u=${localStorage.getItem("userId")}`
    ).then((res) => {
      res = fromJS(res);
      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["currentTableInfo"],
        value: res
      });

      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["scoreCard"],
        value: fromJS({
          data: res.get("basic"),
          type: "basic"
        })
      });

      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["currentPage"],
        value: 2
      });

      dispatch({
        type: layoutConstant.CHANGE_ITEM,
        target: ["pageLoading"],
        value: false
      });
    });
  };
};

export const inputValueChange = (target, value) => ({
  type: constant.CHANGE_FACTOR_VALUE,
  target,
  value
});

export const submitScore = (currentTableInfo, projectInfo) => {
  return (dispatch) => {
    // 先校验数据是否通过
    let validateBasic = false;
    let validateNormal = false;
    let validateSub = false;

    // 校验基本因素层
    const basicValues = currentTableInfo
      .get("basic")
      .filter((i) => i.get("status", 1) !== 0)
      .map((i) => i.get("value", -1))
      .toArray();

    if (basicValues.indexOf(-1) === -1 && parseInt(sum(basicValues)) === 100) {
      validateBasic = true;
    }

    // 校验派生因素层
    const normalValues = currentTableInfo
      .get("normal")
      .filter((i) => i.get("status", 1) !== 0)
      .map((i) => i.get("value", -1))
      .toArray();
    if (
      normalValues.indexOf(-1) === -1 &&
      parseInt(sum(normalValues)) % 100 === 0
    ) {
      validateNormal = true;
    }

    // 校验因子层
    const subValues = currentTableInfo
      .get("sub")
      .filter((i) => i.get("status", 1) !== 0)
      .map((i) => i.get("value", -1))
      .toArray();
    if (subValues.indexOf(-1) === -1 && parseInt(sum(subValues)) % 100 === 0) {
      validateSub = true;
    }

    if (validateBasic && validateNormal && validateSub) {
      let data = currentTableInfo.set("scorer", localStorage.getItem("userId"));
      Post(`${API.SCORE}?rounds=${projectInfo.get("status")}`, data).then(
        (res) => {
          message.success("提交成功！");
          dispatch({
            type: constant.CHANGE_ITEM,
            target: ["currentPage"],
            value: 3
          });
        }
      );
    } else {
      message.error("分数校验不通过");
    }
  };
};

export const autoSave = () => ({
  type: constant.AUTO_SAVE
});

export const loadAutoSave = (tableId) => ({
  type: constant.LOAD_AUTO_SAVE,
  tableId
});
