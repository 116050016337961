import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { List } from "immutable";

import { Select, Button, Row, Col, Radio, Input, InputNumber } from "antd";

class BasicStep extends React.Component {
  render() {
    const basics = this.props.factorList.get("basic");
    const selectedBasics = this.props.newTable.get("basic", new List());

    return (
      <div>
        <Row gutter={15}>
          <Col span={16} offset={2}>
            <Select
              showSearch
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="请选择基本因素"
              style={{ width: "100%" }}
              value={this.props.currentSelect}
              onChange={(value) => this.props.changeCurrectSelect(value)}
            >
              {basics.map((basic) => (
                <Select.Option key={basic.get("id")} value={basic.get("id")}>
                  {basic.get("name")}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={this.props.addBasic}
            >
              添加
            </Button>
          </Col>
        </Row>

        {selectedBasics.map((b, basicIndex) => (
          <Row gutter={15} className="basic-item" key={`basic${b.get("id")}`}>
            <Col span={6}>
              {basicIndex + 1}. {b.get("name")}
            </Col>
            <Col span={4}>
              <Radio.Group
                className="value-type"
                value={b.get("type", 1)}
                onChange={(e) => {
                  let newBasic = b.set("type", e.target.value);
                  this.props.changeBasic(basicIndex, newBasic);
                }}
              >
                <Radio value={1}>单值型</Radio>
                <Radio value={2}>范围型</Radio>
              </Radio.Group>
            </Col>
            <Col span={8}>
              {b.get("type", 1) === 2 ? (
                <Input.Group compact>
                  <InputNumber
                    size="small"
                    style={{ width: 100, textAlign: "center" }}
                    onChange={(value) => {
                      let newBasic = b.set("minimum", value);
                      this.props.changeBasic(basicIndex, newBasic);
                    }}
                    min={0}
                    max={100}
                    placeholder="最小值"
                    value={b.get("minimum")}
                  />
                  <Input
                    size="small"
                    className="site-input-split"
                    style={{
                      width: 30,
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: "none"
                    }}
                    placeholder="~"
                    disabled
                  />
                  <InputNumber
                    size="small"
                    min={0}
                    max={100}
                    className="site-input-right"
                    style={{
                      width: 100,
                      textAlign: "center"
                    }}
                    onChange={(value) => {
                      let newBasic = b.set("maximum", value);
                      this.props.changeBasic(basicIndex, newBasic);
                    }}
                    placeholder="最大值"
                    value={b.get("maximum")}
                  />
                </Input.Group>
              ) : null}
            </Col>
            <Col span={4}>
              <Button
                type="danger"
                onClick={() => this.props.removeBasic(b.get("id"))}
              >
                移除
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

const STP = (state) => {
  return {
    factorList: state.getIn(["project", "factorList"]),
    newTable: state.getIn(["project", "newTable"]),
    currentSelect: state.getIn(["project", "currentSelect", "basic"])
  };
};

const DTP = (dispatch) => {
  return {
    changeCurrectSelect: (value) => {
      dispatch(action.changeItem(["currentSelect", "basic"], value));
    },
    addBasic: () => {
      dispatch(action.addBasic());
    },
    changeBasic: (basicIndex, newBasic) => {
      dispatch(action.changeItem(["newTable", "basic", basicIndex], newBasic));
    },
    removeBasic: (basicId) => {
      dispatch(action.removeBasic(basicId));
    }
  };
};

export default connect(STP, DTP)(BasicStep);
