import React from "react";
import { connect } from "react-redux";
import { actionCreator, constant } from "./store";
import { action as layoutAction } from "store/Layout";

import { Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AdminLayout from "layout/AdminLayout";
import FactorItem from "./components/factorItem";
import FactorModal from "./components/factorModal";

import { Get } from "utils/request";
import { API } from "utils/api";
import { fromJS, Map } from "immutable";

class FactorPage extends React.Component {
  componentDidMount() {
    this.props.initFactorList();
  }

  render() {
    return (
      <AdminLayout
        title="因素管理"
        buttonGroup={[
          <Button
            type="primary"
            key="1"
            icon={<PlusOutlined />}
            onClick={this.props.addFactorModal}
          >
            添加基本因素
          </Button>
        ]}
        content={
          <React.Fragment>
            <FactorModal />

            <Row gutter={15}>
              {this.props.factorList.get("basic").map((item) => (
                <FactorItem key={item.get("id")} basic={item} />
              ))}
            </Row>
          </React.Fragment>
        }
      />
    );
  }
}

const mapSTP = (state) => {
  return {
    factorList: state.getIn(["factor", "factorList"])
  };
};

const mapDTP = (dispatch) => {
  return {
    initFactorList() {
      dispatch(layoutAction.changeItem(["pageLoading"], true));
      Get(API.FACTOR_LIST).then((res) => {
        dispatch(actionCreator.changeItem("factorList", fromJS(res)));
        dispatch(layoutAction.changeItem(["pageLoading"], false));
      });
    },
    addFactorModal() {
      dispatch(
        actionCreator.changeModal(
          new Map({
            show: true,
            title: "添加基本因素",
            action: constant.ADD_BASIC
          })
        )
      );
    }
  };
};

export default connect(mapSTP, mapDTP)(FactorPage);
