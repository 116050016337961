import React from "react";
import { action } from "store/Mark";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  Slider,
  InputNumber,
  Row,
  Col,
  Button,
  Breadcrumb,
  Tag,
  Affix
} from "antd";

import { sum } from "utils/arr";

const StyledScoreCard = styled.div`
  && {
    .title {
      text-align: left;
      padding: 0;
      margin-bottom: 2em;
    }

    .bread-crumb {
      margin-bottom: 1em;
    }
  }

  .factor-item {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px dashed #eee;

    .right-area {
      text-align: center;

      .extend-btn {
        margin-top: 1.5em;
        margin-left: 10px;
      }
    }
  }
`;

class InputItem extends React.Component {
  onChange = (value) => {
    this.setState({
      inputValue: value
    });
  };

  render() {
    const { item, scoreCard, inputValueChange } = this.props;

    const inputValue = scoreCard
      .get("data")
      .find((i) => i.get("id") === item.get("id"))
      .get("value");

    let marks = { 0: "0", 100: "100" };

    if (item.get("minimum", null) !== null) {
      marks[item.get("minimum")] = item.get("minimum");
      delete marks[0];
    }

    if (item.get("maximum", null) !== null) {
      marks[item.get("maximum")] = item.get("maximum");
      delete marks[100];
    }

    if (item.get("mean", null) !== null) {
      marks[parseInt(item.get("mean"))] = {
        style: { color: "#0090ff" },
        label: <strong>{item.get("mean").toFixed(1)}</strong>
      };
    }

    // TODO 没有处理不填项

    return (
      <Row style={{ marginBottom: "1em" }} gutter={15}>
        <Col span={14}>
          <Slider
            min={item.get("minimum") === null ? 0 : item.get("minimum")}
            max={item.get("maximum") === null ? 100 : item.get("maximum")}
            marks={marks}
            disabled={item.get("status", 1) !== 1}
            onChange={(value) => {
              inputValueChange(item, value);
            }}
            value={typeof inputValue === "number" ? inputValue : 0}
          />
        </Col>
        <Col span={9}>
          <InputNumber
            disabled={item.get("status", 1) !== 1}
            min={item.get("minimum") === null ? 0 : item.get("minimum")}
            max={item.get("maximum") === null ? 100 : item.get("maximum")}
            value={inputValue}
            style={{ width: "100%" }}
            onChange={(value) => {
              inputValueChange(item, value);
            }}
          />
        </Col>
      </Row>
    );
  }
}

class ScoreCard extends React.Component {
  render() {
    const {
      scoreCard,
      inputValueChange,
      currentTableInfo,
      changeScoreCard
    } = this.props;
    const scoreValues = scoreCard
      .get("data")
      .filter((i) => i.get("status", 1) !== 0)
      .map((i) => i.get("value", 0));

    let currentTotal = 0;
    let checkStatus = false;

    if (scoreValues.size === 0) {
      currentTotal = "不填";
      checkStatus = true;
    } else {
      // 合计
      currentTotal = parseInt(sum(scoreValues));
      // 检查状态
      checkStatus = currentTotal === 100 && scoreValues.indexOf(0) === -1;
    }

    return (
      <StyledScoreCard>
        <div className="title">
          <Breadcrumb className="bread-crumb" separator=">">
            <Breadcrumb.Item
              onClick={() => {
                changeScoreCard("basic");
              }}
            >
              <Tag style={{ boxShadow: "1px 1px #aaa", marginRight: 0, marginBottom: 5 }}>
                基本因素层
                {scoreCard.get("type") !== "basic"
                  ? `(${scoreCard.getIn(["parent", "basic", "name"])})`
                  : ""}
              </Tag>
            </Breadcrumb.Item>
            {["normal", "sub"].indexOf(scoreCard.get("type")) !== -1 ? (
              <Breadcrumb.Item
                onClick={() => {
                  changeScoreCard("normal");
                }}
              >
                <Tag style={{ boxShadow: "1px 1px #aaa", marginRight: 0 }}>
                  派生因素层
                  {scoreCard.get("type") === "sub"
                    ? `(${scoreCard.getIn(["parent", "normal", "name"])})`
                    : ""}
                </Tag>
              </Breadcrumb.Item>
            ) : null}

            {scoreCard.get("type") === "sub" ? (
              <Breadcrumb.Item
                onClick={() => {
                  changeScoreCard("sub");
                }}
              >
                <Tag style={{ boxShadow: "1px 1px #aaa" }}>因子层</Tag>
              </Breadcrumb.Item>
            ) : null}
          </Breadcrumb>

          <Affix offsetTop={0}>
            <div className="tag-group">
              <Tag color={currentTotal === 100 ? "green" : "red"}>
                当前合计：
                {currentTotal}
              </Tag>
              {checkStatus ? (
                <Tag color="green">校验通过</Tag>
              ) : (
                <Tag color="red">校验未通过</Tag>
              )}
            </div>
          </Affix>
        </div>

        {scoreCard.get("data").map((item, index) => {
          // 判断有没有下层
          let hasNext = true;

          if (scoreCard.get("type") === "basic") {
            hasNext =
              currentTableInfo
                .get("normal")
                .filter((i) => i.get("parent_id") === item.get("id")).size !==
              0;
          } else if (scoreCard.get("type") === "normal") {
            hasNext =
              currentTableInfo
                .get("sub")
                .filter((i) => i.get("parent_id") === item.get("id")).size !==
              0;
          } else {
            hasNext = false;
          }

          return (
            <div className="factor-item" key={item.get("id")}>
              <Row gutter={5}>
                <Col span={18}>
                  <div className="name">
                    {index + 1}. {item.get("name")}{" "}
                    {item.get("status", 1) === 1 ? null : "（不填）"}
                  </div>
                  <InputItem
                    item={item}
                    scoreCard={scoreCard}
                    inputValueChange={inputValueChange}
                  />
                </Col>
                <Col span={6} className="right-area">
                  {hasNext ? (
                    <Button
                      shape="round"
                      className="extend-btn"
                      size="small"
                      onClick={() => changeScoreCard(item)}
                    >
                      展开
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </div>
          );
        })}
      </StyledScoreCard>
    );
  }
}

const STP = (state) => {
  return {
    scoreCard: state.getIn(["mark", "scoreCard"]),
    currentTableInfo: state.getIn(["mark", "currentTableInfo"])
  };
};

const DTP = (dispatch) => {
  return {
    inputValueChange: (target, value) => {
      dispatch(action.inputValueChange(target, value));
    },
    changeScoreCard: (source) => {
      dispatch(action.changeScoreCard(source));
      dispatch(action.autoSave());
    }
  };
};

export default connect(STP, DTP)(ScoreCard);
