export const CHANGE_MODAL = "factor/CHANGE_MODAL";
export const CHANGE_ITEM = "factor/CHANGE_ITEM";
export const CHANGE_LOADING = "factor/CHANGE_LOADING";
export const CHANGE_DATA_LOADING = "factor/CHANGE_DATA_LOADING";

export const ADD_BASIC = "factor/ADD_BASIC";
export const ADD_NORMAL = "factor/ADD_NORMAL";
export const ADD_SUB = "factor/ADD_SUB";

export const UPDATE_BASIC = "factor/UPDATE_BASIC";
export const UPDATE_NORMAL = "factor/UPDATE_NORMAL";
export const UPDATE_SUB = "factor/UPDATE_SUB";

export const DELETE_BASIC = "factor/DELETE_BASIC";
export const DELETE_NORMAL = "factor/DELETE_NORMAL";
export const DELETE_SUB = "factor/DELETE_SUB";
