import styled from "styled-components";
import { Col } from "antd";

const StyledFactorItem = styled(Col)`
  .collapse-box {
    background: #f7f7f7;
    border-radius: 2px;
    margin-bottom: 24px;
    overflow: hidden;

    .factor-item-panel {
      border: none;
    }

    .footer {
      border-top: 1px solid #eee;
      padding-top: 15px;
      text-align: right;

      button {
        margin-left: 10px;
      }
    }
  }
`;

const StyledNormalFactor = styled.div`
  color: #272c36;
  line-height: 1.25;
  margin-bottom: 1em;

  .header {
    cursor: pointer;
    padding-left: 2px;
  }

  .content {
    margin-top: 10px;
  }
`;

export { StyledNormalFactor, StyledFactorItem };
