import { combineReducers } from "redux-immutable";

import { reducer as layoutReducer } from "./Layout";
import { reducer as loginReducer } from "./Login";
import { reducer as projectReducer } from "./Project";
import { reducer as markReducer } from "./Mark";

import { reducer as factorReducer } from "../pages/Factor/store";

const reducer = combineReducers({
  layout: layoutReducer,
  factor: factorReducer,
  login: loginReducer,
  project: projectReducer,
  mark: markReducer
});

export default reducer;
