import * as constant from "./constant";
import { fromJS } from "immutable";

const defaultState = fromJS({
  username: "",
  password: "",
  remember: true,
  loading: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constant.CHANGE_ITEM:
      return state.setIn(action.target, action.value);
    default:
      return state;
  }
};
