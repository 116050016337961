import React from "react";
import { action } from "store/Mark";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SignPage from "./components/SignPage";
import TaskPage from "./components/TaskPage";
import ScorePage from "./components/ScorePage";

import OkPage from "./components/OkPage";

const page = {
  0: <SignPage />,
  1: <TaskPage />,
  2: <ScorePage />,
  3: <OkPage />
};

class MarkerPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadProject(this.props.match.params.id);
  }
  render() {
    return page[this.props.currentPage];
  }
}

const STP = (state) => {
  return {
    currentPage: state.getIn(["mark", "currentPage"])
  };
};

const DTP = (dispatch) => {
  return {
    loadProject: (projectId) => {
      dispatch(action.loadProject(projectId));
    }
  };
};

export default withRouter(connect(STP, DTP)(MarkerPage));
