import * as constant from "./constant";
import { fromJS } from "immutable";
import { message } from "antd";

const defaultState = fromJS({
  userInfo: {},
  modal: {
    isLogin: false,
    scoreDrawer: false,
    signModalVisible: false
  },
  currentPage: 0,
  scoreCard: {
    data: [],
    type: -1,
    parent: {
      basic: undefined,
      normal: undefined
    }
  },
  canLoadSave: false,
  projectInfo: {
    tables: []
  },
  currentTableInfo: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constant.CHANGE_ITEM:
      return state.setIn(action.target, action.value);

    case constant.CHANGE_FACTOR_VALUE:
      {
        // Step1. 设置scoreCard的data
        const scoreCardTargetIndex = state
          .getIn(["scoreCard", "data"])
          .findIndex((i) => i.get("id") === action.target.get("id"));

        state = state.setIn(
          ["scoreCard", "data", scoreCardTargetIndex, "value"],
          action.value
        );

        // Step2. 设置currentTable的value
        const targetIndex = state
          .getIn(["currentTableInfo", state.getIn(["scoreCard", "type"])])
          .findIndex((i) => i.get("id") === action.target.get("id"));

        state = state.setIn(
          [
            "currentTableInfo",
            state.getIn(["scoreCard", "type"]),
            targetIndex,
            "value"
          ],
          action.value
        );
      }
      return state;

    case constant.CHANGE_SCORE_CARD:
      // 根据type判断
      if (["basic", "normal", "sub"].indexOf(action.source) !== -1) {
        // 点击面包屑的时候（因子层不操作）
        if (action.source === "basic") {
          return state
            .setIn(
              ["scoreCard", "data"],
              state.getIn(["currentTableInfo", "basic"])
            )
            .setIn(["scoreCard", "type"], "basic");
        } else if (action.source === "normal") {
          return state
            .setIn(
              ["scoreCard", "data"],
              state
                .getIn(["currentTableInfo", "normal"])
                .filter(
                  (i) =>
                    i.get("parent_id") ===
                    state.getIn(["scoreCard", "parent", "basic", "id"])
                )
            )
            .setIn(["scoreCard", "type"], "normal");
        } else {
          // 因子的时候不动它
          return state;
        }
      } else if (typeof action.source === "string") {
        // 点击评分卡跳转
        const [type, target] = action.source.split("-");

        if (type === "basic") {
          return state
            .set(
              "scoreCard",
              fromJS({
                data: state.getIn(["currentTableInfo", "basic"]),
                type: "basic"
              })
            )
            .setIn(["modal", "scoreDrawer"], false);
        } else if (type === "normal") {
          // 找到目标
          const targetNormal = state
            .getIn(["currentTableInfo", "normal"])
            .find((i) => i.get("id") === parseInt(target));

          const targetBasic = state
            .getIn(["currentTableInfo", "basic"])
            .find((i) => i.get("id") === targetNormal.get("parent_id"));

          return state
            .set(
              "scoreCard",
              fromJS({
                data: state
                  .getIn(["currentTableInfo", "normal"])
                  .filter((i) => i.get("parent_id") === targetBasic.get("id")),
                type: "normal",
                parent: {
                  basic: targetBasic
                }
              })
            )
            .setIn(["modal", "scoreDrawer"], false);
        } else {
          // 为sub的时候
          const targetSub = state
            .getIn(["currentTableInfo", "sub"])
            .find((i) => i.get("id") === parseInt(target));

          const targetNormal = state
            .getIn(["currentTableInfo", "normal"])
            .find((i) => i.get("id") === targetSub.get("parent_id"));

          const targetBasic = state
            .getIn(["currentTableInfo", "basic"])
            .find((i) => i.get("id") === targetNormal.get("parent_id"));

          return state
            .set(
              "scoreCard",
              fromJS({
                data: state
                  .getIn(["currentTableInfo", "sub"])
                  .filter((i) => i.get("parent_id") === targetNormal.get("id")),
                type: "sub",
                parent: {
                  basic: targetBasic,
                  normal: targetNormal
                }
              })
            )
            .setIn(["modal", "scoreDrawer"], false);
        }
      } else {
        // 展开下级的时候
        let targetType = "basic";
        let newScoreCardData = undefined;

        if (state.getIn(["scoreCard", "type"]) === "basic") {
          // 从normal中找数据
          targetType = "normal";
          newScoreCardData = state
            .getIn(["currentTableInfo", targetType])
            .filter((i) => i.get("parent_id") === action.source.get("id"));
        } else if (state.getIn(["scoreCard", "type"]) === "normal") {
          // 从sub中找数据
          targetType = "sub";
          newScoreCardData = state
            .getIn(["currentTableInfo", targetType])
            .filter((i) => i.get("parent_id") === action.source.get("id"));
        }

        return state
          .setIn(["scoreCard", "data"], newScoreCardData)
          .setIn(
            ["scoreCard", "parent", state.getIn(["scoreCard", "type"])],
            action.source
          )
          .setIn(["scoreCard", "type"], targetType);
      }

    /**
     * 自动保存
     */
    case constant.AUTO_SAVE:
      let tableId = state.getIn(["currentTableInfo", "id"]);
      // 保存mark的数据
      localStorage.setItem(tableId, JSON.stringify(state.toJS()));
      message.success("自动保存成功");
      return state;

    case constant.LOAD_AUTO_SAVE:
      let localMarkData = localStorage.getItem(action.tableId);
      if (state.get("canLoadSave")) {
        // 如果可以加载自动保存
        return fromJS(JSON.parse(localMarkData));
      }
      if (localMarkData) {
        return state.set("canLoadSave", true);
      }
      return state;

    default:
      return state;
  }
};
