import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { Select, Row, Col, Button, Tag } from "antd";

import { List } from "immutable";

class SubStep extends React.Component {
  render() {
    const selectedNormals = this.props.newTable.get("normal", new List());

    return (
      <div>
        {selectedNormals.map((normal) => {
          const subs = this.props.factorList
            .get("sub", new List())
            .filter((f) => f.get("parent_id") === normal.get("id"));
          const selectedSubs = this.props.newTable
            .get("sub", new List())
            .filter((f) => f.get("parent_id") === normal.get("id"));
          return (
            <div className="sub-item" key={`normal${normal.get("id")}`}>
              <Row gutter={15}>
                <Col span={8}>{normal.get("name")}</Col>
                <Col span={10}>
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="请选择因子"
                    style={{ width: "100%" }}
                    value={this.props.currentSelect.getIn([
                      "sub",
                      normal.get("id")
                    ])}
                    onChange={(value) =>
                      this.props.changeCurrectSelect(normal.get("id"), value)
                    }
                  >
                    {subs.map((sub) => (
                      <Select.Option
                        key={`sub${sub.get("id")}`}
                        value={sub.get("id")}
                      >
                        {sub.get("name")}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={4}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => this.props.addSub(normal.get("id"))}
                  >
                    添加
                  </Button>
                </Col>
              </Row>

              <div style={{ marginTop: "1em" }}>
                {selectedSubs.map((selectedSub, selectedSubIndex) => (
                  <Tag
                    closable
                    color="blue"
                    key={`normal${selectedSub.get("id")}`}
                    onClose={() => this.props.removeSub(selectedSub.get("id"))}
                  >
                    {selectedSub.get("name")}
                  </Tag>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const STP = (state) => {
  return {
    factorList: state.getIn(["project", "factorList"]),
    newTable: state.getIn(["project", "newTable"]),
    currentSelect: state.getIn(["project", "currentSelect"])
  };
};

const DTP = (dispatch) => {
  return {
    changeCurrectSelect: (normalId, value) => {
      dispatch(action.changeItem(["currentSelect", "sub", normalId], value));
    },
    addSub: (normalId) => {
      dispatch(action.addSub(normalId));
    },
    changeSub: (subIndex, newSub) => {
      dispatch(action.changeItem(["newTable", "sub", subIndex], newSub));
    },
    removeSub: (subId) => {
      dispatch(action.removeSub(subId));
    }
  };
};

export default connect(STP, DTP)(SubStep);
