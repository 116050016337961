import React from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import GlobalStyle from "./layout/globalStyle";
import store from "./store";
import { ConfigProvider, Result } from "antd";
import { ClusterOutlined, ProjectOutlined } from "@ant-design/icons";

import zhCN from "antd/es/locale/zh_CN";

import FactorPage from "./pages/Factor";
import ProjectPage from "./pages/Project/Manage";
import ProjectDetailPage from "./pages/Project/Detail";
import ProjectResultPage from "./pages/Project/Result";

import MarkerPage from "./pages/Mark";
import LoginPage from "./pages/Login";
import TestPage from "./pages/Test";

export const routerList = [
  {
    title: "项目管理",
    route: "/",
    icon: <ProjectOutlined />,
    component: ProjectPage,
    admin: true
  },
  {
    title: "因素管理",
    route: "/factor",
    icon: <ClusterOutlined />,
    component: FactorPage,
    admin: true
  },
  {
    title: "项目详情",
    route: "/project/:id",
    icon: <ProjectOutlined />,
    component: ProjectDetailPage,
    notInNav: true,
    admin: true
  },
  {
    title: "项目评分结果",
    route: "/project/result/:id",
    component: ProjectResultPage,
    notInNav: true,
    admin: true
  },
  {
    title: "评分",
    route: "/mark/:id",
    component: MarkerPage
  },
  {
    title: "登录",
    route: "/login",
    component: LoginPage
  },
  {
    title: "测试",
    route: "/test",
    component: TestPage
  }
];

const recursiveRouteList = (item, parentRoute = "") => {
  return item.subItems ? (
    item.subItems.map((subItem) => recursiveRouteList(subItem, item.route))
  ) : (
    <Route
      exact
      key={parentRoute + item.route}
      path={parentRoute + item.route}
      component={item.component}
    />
  );
};

class Page404 extends React.PureComponent {
  render() {
    return <Result status="404" title="404" subTitle="你迷路了哦~" />;
  }
}

class App extends React.Component {
  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <GlobalStyle />
          <BrowserRouter>
            <Switch>
              {routerList.map((item) => recursiveRouteList(item))}
              <Route component={Page404} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default App;
