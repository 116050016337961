import React from "react";
import { connect } from "react-redux";
import { action } from "store/Project";
import { StyledQRcodeModal } from "../style";

class QrcodeModal extends React.PureComponent {
  render() {
    return (
      <StyledQRcodeModal
        title="右键保存二维码"
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.closeQRcodeModal}
        className="qrcode-modal"
      >
        <div className="qrcode">
          <img width={320} alt="qrcode" src={this.props.currentProjectQrcode} />
        </div>
      </StyledQRcodeModal>
    );
  }
}

const mapSTP = (state) => {
  return {
    visible: state.getIn(["project", "modal", "qrcode"]),
    currentProjectQrcode: state.getIn(["project", "currentProjectQrcode"])
  };
};

const mapDTP = (dispatch) => {
  return {
    closeQRcodeModal: () => {
      dispatch(action.changeItem(["modal", "qrcode"], false));
    }
  };
};

export default connect(mapSTP, mapDTP)(QrcodeModal);
