import * as constant from "./constant";
import { Get } from "utils/request";
import { API } from "utils/api";
import { message } from "antd";

export const changeItem = (target, value) => ({
  type: constant.CHANGE_ITEM,
  target: target,
  value: value
});

export const logout = (history) => {
  return (dispatch) => {
    Get(API.LOGOUT).then(() => {
      message.success("注销成功");
      localStorage.removeItem("adminInfo");
      history.push("/login");
    });
  };
};
