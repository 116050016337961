import React from "react";
import { action } from "store/Mark";
import { connect } from "react-redux";

import { Drawer, Tree, Tag, Button, Popconfirm } from "antd";
import {
  DownOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import styled from "styled-components";
import { sum } from "utils/arr";

const StyledScoreDrawer = styled.div`
  padding: 5px 0;

  .tree {
    margin-bottom: 1em;
  }
`;

const YesIcon = () => (
  <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: 5 }} />
);
const FalseIcon = () => (
  <CloseCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
);
const NotFillIcon = () => (
  <ExclamationCircleTwoTone twoToneColor="#fa541c" style={{ marginRight: 5 }} />
);

/**
 * 生成评分卡数据
 * @param {Immutable.Map} currentTableInfo 评分表数据
 */
const generateTreeData = (currentTableInfo) => {
  const basicValueArr = currentTableInfo
    .get("basic")
    .filter((i) => i.get("status", 1) !== 0)
    .map((i) => i.get("value", -1));

  const treeData = currentTableInfo.get("basic").map((basic) => {
    let normals = currentTableInfo
      .get("normal")
      .filter((i) => i.get("parent_id") === basic.get("id"));
    const normalValueArr = normals
      .filter((i) => i.get("status", 1) !== 0)
      .map((i) => i.get("value", -1));

    normals = normals.map((normal) => {
      let subs = currentTableInfo
        .get("sub")
        .filter((i) => i.get("parent_id") === normal.get("id"));
      const subValueArr = subs
        .filter((i) => i.get("status", 1) !== 0)
        .map((i) => i.get("value", -1));

      subs = subs.map((sub) => {
        return {
          key: `sub-${sub.get("id")}`,
          title: (
            <div>
              {subValueArr.get(-1) !== -1 &&
              parseInt(sum(subValueArr.toArray())) !== 100 ? (
                <FalseIcon key="false" />
              ) : sub.get("value") ? (
                <YesIcon key="yes" />
              ) : (
                <NotFillIcon key="not" />
              )}
              {sub.get("value") ? (
                <Tag key={`sub${sub.get("id")}`}>{sub.get("value")}</Tag>
              ) : null}
              {sub.get("name")}
            </div>
          )
        };
      });

      return {
        key: `normal-${normal.get("id")}`,
        title: (
          <div>
            {normal.get("status", 1) === 0 ? (
              <YesIcon key="yes" />
            ) : normal.get("value", -1) === -1 ? (
              <NotFillIcon key="not" />
            ) : parseInt(sum(normalValueArr.toArray())) === 100 ? (
              <YesIcon key="yes" />
            ) : (
              <FalseIcon key="false" />
            )}
            {normal.get("value") ? (
              <Tag key={`normal${normal.get("id")}`}>{normal.get("value")}</Tag>
            ) : null}
            {normal.get("name")}
          </div>
        ),
        children: subs
      };
    });

    return {
      key: `basic-${basic.get("id")}`,
      title: (
        <div>
          {basicValueArr.get(-1) !== -1 &&
          parseInt(sum(basicValueArr.toArray())) !== 100 ? (
            <FalseIcon key="false" />
          ) : basic.get("value") ? (
            <YesIcon key="yes" />
          ) : (
            <NotFillIcon key="not" />
          )}
          {basic.get("value") ? (
            <Tag key={`baisc${basic.get("id")}`}>{basic.get("value")}</Tag>
          ) : null}
          {basic.get("name")}
        </div>
      ),
      children: normals
    };
  });

  return treeData.toJS();
};

/**
 * 评分卡
 */
class ScoreDrawer extends React.Component {
  render() {
    const { visible, closeDrawer, currentTableInfo, projectInfo, submit } =
      this.props;
    const treeData = generateTreeData(currentTableInfo);

    return (
      <Drawer
        title={
          <div>
            <h2>导航卡</h2>
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              <p style={{ margin: 0 }}>
                剩余未评：
                {["basic", "normal", "sub"]
                  .map(
                    (item) =>
                      currentTableInfo
                        .get(item)
                        .filter((i) => i.get("status", 1) !== 0).size -
                      currentTableInfo
                        .get(item)
                        .filter((i) => i.get("status", 1) !== 0)
                        .filter((i) => i.get("value")).size
                  )
                  .join(" | ")}
              </p>
              <p
                style={{
                  fontSize: 12,
                  color: "#8c8c8c",
                  fontWeight: 500,
                  margin: "5px 0 0 0"
                }}
              >
                基本因素层 | 派生因素层 | 因子层
              </p>
            </div>
          </div>
        }
        placement="right"
        closable={true}
        visible={visible}
        bodyStyle={{ padding: 0 }}
        onClose={closeDrawer}
        footer={
          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: 8 }}>检查完毕后点击下面的提交按钮</div>
            <Popconfirm
              title="提交评分？"
              onConfirm={() => submit(currentTableInfo, projectInfo)}
              okText="确定"
              cancelText="再看看"
            >
              <Button type="primary">提交</Button>
            </Popconfirm>
          </div>
        }
      >
        <StyledScoreDrawer>
          <Tree
            showIcon
            defaultExpandAll
            className="tree"
            switcherIcon={<DownOutlined />}
            treeData={treeData}
            onSelect={(selectedKeys, info) => {
              this.props.changeScoreCard(selectedKeys[0]);
            }}
          />
        </StyledScoreDrawer>
      </Drawer>
    );
  }
}

const STP = (state) => {
  return {
    visible: state.getIn(["mark", "modal", "scoreDrawer"]),
    currentTableInfo: state.getIn(["mark", "currentTableInfo"]),
    projectInfo: state.getIn(["mark", "projectInfo"])
  };
};

const DTP = (dispatch) => {
  return {
    closeDrawer: () => {
      dispatch(action.changeItem(["modal", "scoreDrawer"], false));
    },
    changeScoreCard: (source) => {
      dispatch(action.changeScoreCard(source));
      dispatch(action.autoSave());
    },
    submit: (currentTableInfo, projectInfo) => {
      dispatch(action.submitScore(currentTableInfo, projectInfo));
    }
  };
};

export default connect(STP, DTP)(ScoreDrawer);
