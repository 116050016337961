import React from "react";
import { Map, fromJS, List } from "immutable";
import { action } from "store/Project";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Steps, Button, message } from "antd";
import { StyledCreateTableModal } from "../style";

import BaseInfoStep from "./BaseInfoStep";
import BasicStep from "./BasicStep";
import NormalStep from "./NormalStep";
import SubStep from "./SubStep";

import { API } from "utils/api";
import { Post, Put } from "utils/request";

const steps = [
  { title: "基本信息", content: <BaseInfoStep /> },
  { title: "基本因素层", content: <BasicStep /> },
  { title: "派生因素层", content: <NormalStep /> },
  { title: "因子层", content: <SubStep /> }
];

class CreateTableModal extends React.Component {
  render() {
    const {
      current,
      createTableModalVisible,
      closeCreateTableModal,
      changeCurrentStep
    } = this.props;

    return (
      <StyledCreateTableModal
        maskClosable={false}
        keyboard={false}
        title="创建评分表"
        width={"50%"}
        visible={createTableModalVisible}
        onCancel={closeCreateTableModal}
        footer={
          <div>
            <Button style={{ margin: 8 }} onClick={closeCreateTableModal}>
              取消
            </Button>
            {current > 0 && (
              <Button
                style={{ margin: 8 }}
                onClick={() => changeCurrentStep(current - 1)}
              >
                上一步
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  if (current === 0) {
                    // 基本信息
                    if (!this.props.newTable.get("title", false)) {
                      message.error("请先填写评分表名称");
                      return;
                    }
                  }
                  if (current === 1) {
                    // 基本因素层
                    if (!this.props.newTable.get("basic", new List()).size) {
                      message.error("请先添加基本因素");
                      return;
                    }
                  }
                  changeCurrentStep(current + 1);
                }}
              >
                下一步
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  let data = this.props.newTable.set(
                    "project_id",
                    this.props.match.params.id
                  );
                  data = data.set(
                    "basic",
                    data.get("basic").sortBy((i) => i.get("id"))
                  );
                  data = data.set(
                    "normal",
                    data.get("normal").sortBy((i) => i.get("parent_id"))
                  );
                  data = data.set(
                    "sub",
                    data.get("sub").sortBy((i) => i.get("parent_id"))
                  );

                  if (data.get("id")) {
                    Put(`${API.TABLE_BASE}/${data.get("id")}`, data).then(
                      (res) => {
                        // 替换新数据
                        let newProject = this.props.project.setIn(
                          [
                            "tables",
                            this.props.project
                              .get("tables")
                              .findIndex((i) => i.get("id") === data.get("id"))
                          ],
                          fromJS(res)
                        );
                        this.props.changeProject(newProject);
                        message.success("修改成功");
                        this.props.closeCreateTableModal();
                      }
                    );
                  } else {
                    Post(API.TABLE_BASE, data).then((res) => {
                      let newProject = this.props.project.set(
                        "tables",
                        this.props.project.get("tables").push(fromJS(res))
                      );
                      this.props.changeProject(newProject);
                      message.success("创建成功");
                      this.props.closeCreateTableModal();
                    });
                  }
                }}
              >
                完成
              </Button>
            )}
          </div>
        }
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
      </StyledCreateTableModal>
    );
  }
}

const STP = (state) => {
  return {
    createTableModalVisible: state.getIn([
      "project",
      "createTableModalVisible"
    ]),
    current: state.getIn(["project", "currentStep"]),
    newTable: state.getIn(["project", "newTable"]),
    project: state.getIn(["project", "project"])
  };
};

const DTP = (dispatch) => {
  return {
    changeProject: (newProject) => {
      dispatch(action.changeItem(["project"], newProject));
    },
    changeCurrentStep: (value) => {
      dispatch(action.changeItem(["currentStep"], value));
      dispatch(action.changeItem(["currentSelect"], new Map()));
    },
    closeCreateTableModal: () => {
      dispatch(action.changeItem(["createTableModalVisible"], false));
    }
  };
};

export default withRouter(connect(STP, DTP)(CreateTableModal));
