import React from "react";
import { connect } from "react-redux";
import { constant, actionCreator } from "../store";

import { Modal, message, Input } from "antd";

import { Map } from "immutable";
import { API } from "utils/api";
import { Post, Put, Delete } from "utils/request";

class FactorModal extends React.Component {
  constructor(props) {
    super(props);
    this.addFactor = this.addFactor.bind(this);
    this.updateFactor = this.updateFactor.bind(this);
    this.deleteFactor = this.deleteFactor.bind(this);
  }

  addFactor = (type) => {
    let API_, factorTarget, requestData;
    const {
      modal,
      factorList,
      closeModal,
      changeLoading,
      factorParentId,
      changeFactorList
    } = this.props;

    switch (type) {
      case constant.ADD_BASIC:
        API_ = API.FACTOR_BASIC;
        factorTarget = "basic";
        requestData = { name: modal.get("inputValue") };
        break;
      case constant.ADD_NORMAL:
        API_ = API.FACTOR_NORMAL;
        factorTarget = "normal";
        requestData = {
          name: modal.get("inputValue"),
          parent_id: factorParentId
        };
        break;
      case constant.ADD_SUB:
        API_ = API.FACTOR_SUB;
        factorTarget = "sub";
        requestData = {
          name: modal.get("inputValue"),
          parent_id: factorParentId
        };
        break;
      default:
        break;
    }
    return new Promise((resolve, reject) => {
      Post(API_, requestData)
        .then((res) => {
          let newFactorList = factorList.set(
            factorTarget,
            factorList.get(factorTarget).insert(0, new Map(res))
          );
          changeFactorList(newFactorList);
          message.success("添加成功");
        })
        .finally(() => {
          changeLoading();
          closeModal();
        });
    });
  };

  updateFactor = (type) => {
    let API_, factorTarget;
    const {
      modal,
      factorList,
      closeModal,
      changeLoading,
      factorParentId,
      changeFactorList
    } = this.props;

    switch (type) {
      case constant.UPDATE_BASIC:
        API_ = API.FACTOR_BASIC;
        factorTarget = "basic";
        break;
      case constant.UPDATE_NORMAL:
        API_ = API.FACTOR_NORMAL;
        factorTarget = "normal";
        break;
      case constant.UPDATE_SUB:
        API_ = API.FACTOR_SUB;
        factorTarget = "sub";
        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      Put(API_ + `/${factorParentId}`, {
        name: modal.get("inputValue")
      })
        .then((res) => {
          let itemIndex = factorList
            .get(factorTarget)
            .findIndex((item) => item.get("id") === factorParentId);
          let newFactorList = factorList.setIn(
            [factorTarget, itemIndex, "name"],
            modal.get("inputValue")
          );
          changeFactorList(newFactorList);
          message.success("更新成功");
        })
        .finally(() => {
          changeLoading();
          closeModal();
        });
    });
  };

  deleteFactor = (type) => {
    let API_, factorTarget;
    const {
      factorList,
      closeModal,
      changeLoading,
      factorParentId,
      changeFactorList
    } = this.props;

    switch (type) {
      case constant.DELETE_BASIC:
        API_ = API.FACTOR_BASIC;
        factorTarget = "basic";
        break;
      case constant.DELETE_NORMAL:
        API_ = API.FACTOR_NORMAL;
        factorTarget = "normal";
        break;
      case constant.DELETE_SUB:
        API_ = API.FACTOR_SUB;
        factorTarget = "sub";
        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      Delete(API_ + `/${factorParentId}`)
        .then((res) => {
          let itemIndex = factorList
            .get(factorTarget)
            .findIndex((item) => item.get("id") === factorParentId);
          let newFactorList = factorList.deleteIn([factorTarget, itemIndex]);
          changeFactorList(newFactorList);
          message.success("删除成功");
        })
        .finally(() => {
          changeLoading();
          closeModal();
        });
    });
  };

  update = () => {
    const { modal, changeLoading } = this.props;

    changeLoading();

    switch (modal.get("action")) {
      // 添加基本因素
      case constant.ADD_BASIC:
        this.addFactor(constant.ADD_BASIC);
        break;

      // 添加因素
      case constant.ADD_NORMAL:
        this.addFactor(constant.ADD_NORMAL);
        break;

      // 添加因子
      case constant.ADD_SUB:
        this.addFactor(constant.ADD_SUB);
        break;

      // 修改基本因素
      case constant.UPDATE_BASIC:
        this.updateFactor(constant.UPDATE_BASIC);
        break;

      // 修改因素
      case constant.UPDATE_NORMAL:
        this.updateFactor(constant.UPDATE_NORMAL);
        break;

      // 修改因子
      case constant.UPDATE_SUB:
        this.updateFactor(constant.UPDATE_SUB);
        break;

      // 刪除基本因素
      case constant.DELETE_BASIC:
        this.deleteFactor(constant.DELETE_BASIC);
        break;

      // 刪除因素
      case constant.DELETE_NORMAL:
        this.deleteFactor(constant.DELETE_NORMAL);
        break;

      // 刪除因子
      case constant.DELETE_SUB:
        this.deleteFactor(constant.DELETE_SUB);
        break;

      default:
        break;
    }
  };

  render() {
    const { modal, closeModal, changeInputValue, loading } = this.props;

    return (
      <Modal
        visible={modal.get("show")}
        title={modal.get("title")}
        onOk={this.update}
        okButtonProps={{ loading: loading }}
        onCancel={closeModal}
        cancelButtonProps={{ disabled: loading }}
      >
        {[
          constant.DELETE_BASIC,
          constant.DELETE_NORMAL,
          constant.DELETE_SUB
        ].indexOf(modal.get("action")) < 0 ? (
          <Input
            autoFocus
            value={modal.get("inputValue")}
            onChange={changeInputValue}
          />
        ) : (
          `是否删除 ${modal.get("inputValue")} ？`
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.getIn(["factor", "modal"]),
    loading: state.getIn(["factor", "loading"]),
    factorList: state.getIn(["factor", "factorList"]),
    factorParentId: state.getIn(["factor", "factorParentId"])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeInputValue(e) {
      dispatch(
        actionCreator.changeModal(new Map({ inputValue: e.target.value }))
      );
    },
    changeLoading() {
      dispatch(actionCreator.changeLoading());
    },
    changeFactorList(newFactorList) {
      dispatch(actionCreator.changeItem("factorList", newFactorList));
    },
    closeModal() {
      dispatch(
        actionCreator.changeModal(new Map({ show: false, inputValue: "" }))
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FactorModal);
