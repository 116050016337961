import styled from "styled-components";
import { Form } from "antd";
import bg from "assets/login_bg.svg";

export const StyledLoginPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;

  .header {
    margin-top: 3rem;

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 2em;
      color: rgba(0, 0, 0, 0.85);
    }

    .state {
      text-align: center;
      margin-top: 1em;
      font-size: 1em;
      margin-bottom: 40px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .steps-content {
    margin-top: 2em;
  }
`;

export const LoginExtra = styled(Form.Item)`
  .remeber {
    float: left;
  }
  div.extra {
    float: right;

    a {
      margin-left: 15px;
    }
  }
`;
