import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { action as layoutAction } from "store/Layout";

import { Button, Popconfirm, message } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined
} from "@ant-design/icons";

import { API } from "utils/api";
import { fromJS, Map } from "immutable";
import { Get, Delete } from "utils/request";

class ButtonGroup extends React.Component {
  render() {
    return (
      <div className="button-group">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            Get(API.FACTOR_LIST).then((res) => {
              this.props.initCreateTableModal(fromJS(res));
            });
            this.props.showCreateTableModal();
          }}
        >
          创建评分表
        </Button>
        <Button
          type="primary"
          icon={<ImportOutlined />}
          onClick={this.props.showImportTableModal}
        >
          从其他项目导入评分表
        </Button>
        <Popconfirm
          placement="bottomRight"
          title="确定删除该项目吗？"
          onConfirm={() => {
            this.props.chagePageLoading(true);
            const projectId = this.props.match.params.id;
            try {
              Delete(`${API.PROJECT_BASE}/${projectId}`).then((res) => {
                let targetIndex = this.props.projectList.findIndex(
                  (f) => f.get("id") === projectId
                );
                let newProject = this.props.projectList.delete(targetIndex);
                this.props.changeProjectList(newProject);
                this.props.history.push("/");
                message.success("删除成功");
              });
            } finally {
              this.props.chagePageLoading(false);
            }
          }}
        >
          <Button type="danger" icon={<DeleteOutlined />}>
            删除项目
          </Button>
        </Popconfirm>
      </div>
    );
  }
}

const STP = (state) => {
  return {
    projectList: state.getIn(["project", "projectList"])
  };
};

const DTP = (dispatch) => {
  return {
    initCreateTableModal: (factorList) => {
      // 重新加载，factorList，初始化newTable
      dispatch(action.changeItem(["newTable"], new Map()));
      dispatch(action.changeItem(["factorList"], factorList));
      dispatch(action.changeItem(["currentStep"], 0));
      dispatch(action.changeItem(["currentSelect"], new Map()));
    },
    showCreateTableModal: () => {
      dispatch(action.changeItem(["createTableModalVisible"], true));
    },
    changeProjectList: (newProjectList) => {
      dispatch(action.changeItem(["projectList"], newProjectList));
    },
    chagePageLoading: (value) => {
      dispatch(layoutAction.changeItem(["pageLoading"], value));
    },
    showImportTableModal: () => {
      dispatch(action.changeItem(["currentSelect", "project"], undefined));
      dispatch(action.changeItem(["importTableModalVisible"], true));
    }
  };
};

export default withRouter(connect(STP, DTP)(ButtonGroup));
