import React from "react";
import { action } from "store/Mark";
import { connect } from "react-redux";

import styled from "styled-components";
import MarkLayout from "layout/MarkLayout";
import { Input, Form, Button, Modal } from "antd";
import SignatureCanvas from "react-signature-canvas";

const StyledSignPage = styled.div`
  margin-top: 1em;

  .signature-pad-area {
    background: #fff;
    border: 1px solid #e8e8e8;
    height: 30vh;

    .signature-pad {
      width: 100%;
      height: 100%;
    }
  }
`;

/**
 * 签名页
 */
class SignPage extends React.Component {
  state = {
    signature: undefined
  };

  componentDidMount() {
    this.props.currentUserIsScored();
  }

  render() {
    return (
      <MarkLayout
        title={this.props.projectInfo.get("title")}
        description={`共有${
          this.props.projectInfo.get("tables").size
        }张表需要您打分，下面将采集一些您的基本信息`}
        content={
          <StyledSignPage>
            <Modal
              visible={this.props.visible}
              title="检测到已经使用过该系统"
              onOk={() => this.props.changeItem(["currentPage"], 1)}
              onCancel={() =>
                this.props.changeItem(["modal", "isLogin"], false)
              }
            >
              {this.props.userInfo.get("name")}
              专家您好！检测到您曾经使用过该系统，是否使用上次的信息进行评分？
            </Modal>

            <Form
              layout="vertical"
              name="scorer"
              onFinish={(values) => {
                this.props.submitUserInfo(values, this.state.signature);
              }}
              // fields={[
              //   {
              //     name: ["name"],
              //     value: "应兆康"
              //   },
              //   {
              //     name: ["phone"],
              //     value: "18582326523"
              //   },
              //   {
              //     name: ["organization"],
              //     value: "卓越"
              //   }
              // ]}
            >
              <Form.Item
                required
                name="name"
                label="您的姓名？"
                rules={[
                  {
                    required: true,
                    message: "请输入您的姓名~"
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                required
                name="phone"
                label="您的手机号？（系统的唯一标识）"
                rules={[
                  {
                    required: true,
                    message: "请输入您的手机号~"
                  },
                  {
                    pattern: /^1[3|4|5|7|8][0-9]{9}$/,
                    message: "请填写正确的手机号码~"
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                required
                label="请签名（请在下面区域进行签名）"
                name="signature"
              >
                <div className="signature-pad-area">
                  <Button
                    type="primary"
                    size="small"
                    style={{ position: "absolute", right: 0 }}
                    onClick={() => this.signature.clear()}
                  >
                    重置
                  </Button>
                  <SignatureCanvas
                    penColor="black"
                    clearOnResize={false}
                    minWidth={1.5}
                    maxWidth={3}
                    onEnd={() =>
                      this.setState({
                        signature: this.signature.toDataURL("image/png")
                      })
                    }
                    ref={(ref) => {
                      this.signature = ref;
                    }}
                    canvasProps={{
                      className: "signature-pad"
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item name="organization" label="您的单位?">
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  去打分
                </Button>
              </Form.Item>
            </Form>
          </StyledSignPage>
        }
      />
    );
  }
}

const STP = (state) => {
  return {
    visible: state.getIn(["mark", "modal", "isLogin"]),
    projectInfo: state.getIn(["mark", "projectInfo"]),
    userInfo: state.getIn(["mark", "userInfo"])
  };
};

const DTP = (dispatch) => {
  return {
    submitUserInfo: (values, signature) => {
      dispatch(action.submitUserInfo(values, signature));
    },
    changeItem: (target, value) => {
      dispatch(action.changeItem(target, value));
    },
    currentUserIsScored: () => {
      dispatch(action.currentUserIsScored());
    }
  };
};

export default connect(STP, DTP)(SignPage);
