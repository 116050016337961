import React from "react";
import { action } from "store/Layout";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from "../config";

import logo from "assets/gd32n.png";
import {
  Row,
  Col,
  Spin,
  Menu,
  Avatar,
  Layout,
  BackTop,
  Dropdown,
  PageHeader
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined
} from "@ant-design/icons";

import styled from "styled-components";
import { fromJS } from "immutable";
import { routerList } from "App";
import { getShortName } from "utils/user";

const { Header, Sider, Content, Footer } = Layout;

const StyledAdminLayout = styled(Layout)`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    margin: 16px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 8px;
    }

    div {
      color: #fff;
      font-size: 1.3em;
      font-weight: 600;
    }
  }

  .site-layout {
    min-height: 100vh;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
`;

class AdminLayout extends React.Component {
  toggle = () => {
    this.props.changeCollapsed(!this.props.collapsed);
  };

  render() {
    const { collapsed } = this.props;
    const adminInfo = fromJS(JSON.parse(localStorage.getItem("adminInfo")));

    return (
      <StyledAdminLayout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <img src={logo} alt="logo" width={36} />
            {collapsed ? null : <div>{config.title}</div>}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={
              this.props.navSelected
                ? this.props.navSelected
                : [this.props.history.location.pathname]
            }
          >
            {routerList
              .filter((item) => item.admin)
              .map((item) =>
                item.notInNav ? null : (
                  <Menu.Item
                    key={item.route}
                    disabled={item.disabled}
                    onClick={() => this.props.history.push(item.route)}
                  >
                    {item.icon ? item.icon : null}
                    <span>{item.title}</span>
                  </Menu.Item>
                )
              )}
          </Menu>
        </Sider>

        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, borderBottom: "1px solid #f1eeee" }}
          >
            <Row justify="space-between" style={{ paddingRight: "2em" }}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle
                }
              )}

              {/* 用户头像 */}
              <Col span={6} style={{ textAlign: "right" }}>
                <Dropdown
                  trigger="click"
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => this.props.logout(this.props.history)}
                      >
                        <LogoutOutlined />
                        注销
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Avatar
                    size={42}
                    style={{ background: "#007cfa", cursor: "pointer" }}
                  >
                    {adminInfo === null
                      ? ""
                      : getShortName(adminInfo.get("username"))}
                  </Avatar>
                </Dropdown>
              </Col>
            </Row>
          </Header>

          <Spin spinning={this.props.pageLoading} size="large">
            {/* 页头 */}
            <PageHeader
              ghost={false}
              title={this.props.title}
              subTitle={this.props.subtitle}
              extra={this.props.buttonGroup}
              onBack={this.props.onBack}
            >
              {this.props.description}
            </PageHeader>
            {/* 页头结束 */}
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: "calc(100vh - 290px)"
              }}
            >
              {this.props.content}
            </Content>
          </Spin>

          <BackTop />

          <Footer style={{ textAlign: "center", background: "#fff" }}>
            当前版本：V2.2 <br />
            Copyright © {new Date().getFullYear()} <br />
            广东卓越土地房地产评估咨询有限公司 <br /> 技术支持中心
          </Footer>
        </Layout>
      </StyledAdminLayout>
    );
  }
}

const mapSTP = (state) => {
  return {
    collapsed: state.getIn(["layout", "collapsed"]),
    pageLoading: state.getIn(["layout", "pageLoading"]),
    adminInfo: state.getIn(["layout", "adminInfo"])
  };
};

const mapDTP = (dispatch) => {
  return {
    changeCollapsed(value) {
      dispatch(action.changeItem(["collapsed"], value));
    },
    logout: (history) => {
      dispatch(action.logout(history));
    }
  };
};

export default withRouter(connect(mapSTP, mapDTP)(AdminLayout));
