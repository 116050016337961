import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  Avatar,
  Dropdown,
  Menu,
  Col,
  Card,
  Row,
  Tooltip,
  message,
  Descriptions,
  Statistic
} from "antd";
import {
  EditOutlined,
  QrcodeOutlined,
  ProjectOutlined
} from "@ant-design/icons";
import { getProjectStatusTag } from "utils/project";
import { action } from "store/Project";

const StyedProjectItem = styled(Col).attrs({
  span: 8
})`
  .avatar {
    background: #1890ff;
  }

  .basic-info {
    margin-bottom: 1em;
  }

  .overview {
    text-align: center;
    margin-top: 1em;
  }

  .ant-statistic-content {
    font-family: inherit;
  }
`;

class ProjectItem extends React.Component {
  render() {
    const { item, index } = this.props;

    return (
      <StyedProjectItem>
        <Card
          hoverable
          actions={[
            <Tooltip title="修改项目信息" placement="bottom" key="edit">
              <EditOutlined
                key="setting"
                onClick={() =>
                  this.props.history.push(`/project/${item.get("id")}`)
                }
              />
            </Tooltip>,

            <Tooltip title="评分二维码" placement="bottom" key="qrcode">
              <QrcodeOutlined
                onClick={() => {
                  // 判断项目状态
                  if ([1, 2].indexOf(item.get("status")) === -1) {
                    // 不在允许打分的状态
                    message.warning("项目当前状态下不允许评分，请先设置状态");
                  } else {
                    this.props.showQrcodeModal(item.get("qrcode"));
                  }
                }}
              />
            </Tooltip>,

            <Tooltip title="数据结果" placement="bottom" key="qrcode">
              <ProjectOutlined
                onClick={() =>
                  this.props.history.push(`/project/result/${item.get("id")}`)
                }
                // onClick={() => this.props.showResultModal(item.get("id"))}
              />
            </Tooltip>
          ]}
        >
          <Card.Meta
            avatar={
              <Avatar size="large" className="avatar">
                {item.get("title")[0]}
              </Avatar>
            }
            description={
              <Descriptions
                column={2}
                title={item.get("title")}
                className="basic-info"
              >
                <Descriptions.Item label="项目状态">
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu
                        onClick={(e) =>
                          this.props.changeProject(
                            index,
                            parseInt(e.key),
                            item.get("id")
                          )
                        }
                      >
                        {[0, 1, 2, 5].map((statusItem) => (
                          <Menu.Item key={statusItem}>
                            {getProjectStatusTag(statusItem)}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    {getProjectStatusTag(item.get("status"))}
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item label="创建人">
                  {item.getIn(["creator", "username"])}
                </Descriptions.Item>
                <Descriptions.Item label="评分表数">
                  {item.get("tables", { size: 0 }).size}
                </Descriptions.Item>
                <Descriptions.Item label="创建时间">
                  {item.get("create_time")}
                </Descriptions.Item>
              </Descriptions>
            }
          />
          <Row gutter={[15, 15]} justify="space-around" className="overview">
            <Col span={6}>
              <Statistic
                title="参与评分人数"
                value={item.get("scorer_count")}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="第一轮评分数"
                value={
                  item.get("status") >= 1
                    ? item.get("round1_score_count")
                    : "未开始"
                }
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="第二轮评分数"
                value={
                  item.get("status") >= 2
                    ? item.get("round2_score_count")
                    : "未开始"
                }
              />
            </Col>
          </Row>
        </Card>
      </StyedProjectItem>
    );
  }
}

const DTP = (dispatch) => {
  return {
    changeProject: (index, target, projectId) => {
      dispatch(action.updateProject(index, target, projectId));
    },
    showQrcodeModal: (qrcode) => {
      dispatch(action.changeItem(["modal", "qrcode"], true));
      dispatch(action.changeItem(["currentProjectQrcode"], qrcode));
    },
    showResultModal: (projectId) => {
      dispatch(action.changeItem(["modal", "result"], true));
      dispatch(action.changeItem(["currentSelect", "project"], projectId));
    }
  };
};

export default withRouter(connect(null, DTP)(ProjectItem));
