import React from "react";
import { connect } from "react-redux";
import { constant, actionCreator } from "../store";

import { Button, Collapse } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CaretRightOutlined
} from "@ant-design/icons";
import { StyledFactorItem } from "../style";
import NormalFactorPanel from "./normalFactorPanel";

import { Map } from "immutable";

class FactorItem extends React.Component {
  render() {
    const {
      basic,
      factorList,
      addFactorModal,
      updateFactorModal,
      deleteFactorModal
    } = this.props;

    return (
      <StyledFactorItem span={8} key={basic.get("id")}>
        <Collapse
          bordered={false}
          className="collapse-box"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel
            key={basic.get("id")}
            header={basic.get("name")}
            className="factor-item-panel"
            extra={
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFactorModal(basic);
                }}
              />
            }
          >
            {factorList
              .get("normal")
              .filter((f) => f.get("parent_id") === basic.get("id"))
              .map((normal) => (
                <NormalFactorPanel key={normal.get("id")} normal={normal} />
              ))}

            <div className="footer">
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => addFactorModal(basic.get("id"))}
              >
                添加因素
              </Button>
              <Button
                type="primary"
                size="small"
                className="button-color-cyan"
                icon={<EditOutlined />}
                onClick={() => updateFactorModal(basic)}
              >
                修改
              </Button>
            </div>
          </Collapse.Panel>
        </Collapse>
      </StyledFactorItem>
    );
  }
}

const mapSTP = (state) => {
  return {
    factorList: state.getIn(["factor", "factorList"])
  };
};

const mapDTP = (dispatch) => {
  return {
    addFactorModal(basicId) {
      dispatch(
        actionCreator.changeModal(
          new Map({
            show: true,
            title: "添加因素",
            action: constant.ADD_NORMAL
          })
        )
      );
      dispatch(actionCreator.changeItem("factorParentId", basicId));
    },
    updateFactorModal(basic) {
      dispatch(
        actionCreator.changeModal(
          new Map({
            show: true,
            title: "修改基本因素",
            action: constant.UPDATE_BASIC,
            inputValue: basic.get("name")
          })
        )
      );
      dispatch(actionCreator.changeItem("factorParentId", basic.get("id")));
    },
    deleteFactorModal(basic) {
      dispatch(
        actionCreator.changeModal(
          new Map({
            show: true,
            title: "删除基本因素",
            action: constant.DELETE_BASIC,
            inputValue: basic.get("name")
          })
        )
      );
      dispatch(actionCreator.changeItem("factorParentId", basic.get("id")));
    }
  };
};

export default connect(mapSTP, mapDTP)(FactorItem);
