import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  scroll-behavior: smooth;

  body {
    font-family: Apple-System,Arial,Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei",STXihei,sans-serif;
  }

  .button-group {
    .search {
      display: inline-block;
      width: 30em;
    }

    button {
      margin-left: 10px;
    }
  }

  /*控制整个滚动条*/
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-clip: padding-box;
  }

  /*滚动条两端方向按钮*/
  ::-webkit-scrollbar-button {
    display: none;
  }

  /*滚动条中间滑动部分*/
  ::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    /* border-radius: 5px; */
  }

  .button-color-dust {
    background-color: #F5222D;
    border-color: #F5222D;
  
    &:hover, &:focus {
      background-color: #ff4d4f;
      border-color: #ff4d4f;
    }
  
    &:active, &.active {
      background-color: #cf1322;
      border-color: #cf1322;
    }
  }
  
  .button-color-volcano {
    background-color: #FA541C;
    border-color: #FA541C;
  
    &:hover, &:focus {
      background-color: #ff7a45;
      border-color: #ff7a45;
    }
  
    &:active, &.active {
      background-color: #d4380d;
      border-color: #d4380d;
    }
  }
  
  .button-color-sunset {
    background-color: #FAAD14;
    border-color: #FAAD14;
  
    &:hover, &:focus {
      background-color: #ffc53d;
      border-color: #ffc53d;
    }
  
    &:active, &.active {
      background-color: #d48806;
      border-color: #d48806;
    }
  }
  
  .button-color-cyan {
    background-color: #13C2C2;
    border-color: #13C2C2;
  
    &:hover, &:focus {
      background-color: #36cfc9;
      border-color: #36cfc9;
    }
  
    &:active, &.active {
      background-color: #08979c;
      border-color: #08979c;
    }
  }
  
  .button-color-green {
    background-color: #52C41A;
    border-color: #52C41A;
  
    &:hover, &:focus {
      background-color: #73d13d;
      border-color: #73d13d;
    }
  
    &:active, &.active {
      background-color: #389e0d;
      border-color: #389e0d;
    }
  }
  
  .button-color-daybreak {
    background-color: #1890FF;
    border-color: #1890FF;
  
    &:hover, &:focus {
      background-color: #096dd9;
      border-color: #096dd9;
    }
  
    &:active, &.active {
      background-color: #40a9ff;
      border-color: #40a9ff;
    }
  }
  
  .button-color-geekblue {
    background-color: #2F54EB;
    border-color: #2F54EB;
  
    &:hover, &:focus {
      background-color: #1d39c4;
      border-color: #1d39c4;
    }
  
    &:active, &.active {
      background-color: #597ef7;
      border-color: #597ef7;
    }
  }
  
  .button-color-purple {
    background-color: #722ED1;
    border-color: #722ED1;
  
    &:hover, &:focus {
      background-color: #9254de;
      border-color: #9254de;
    }
  
    &:active, &.active {
      background-color: #531dab;
      border-color: #531dab;
    }
  }
`;

export default GlobalStyle;
