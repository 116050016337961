import * as constant from "./constant";
import { fromJS, List } from "immutable";

const defaultState = fromJS({
  projectList: [],
  modal: {
    createProject: false,
    qrcode: false,
    result: false,
    structure: false
  },
  project: {
    tables: []
  },
  createTableModalVisible: false,
  newProject: "",
  currentStep: 0,
  currentSelect: {
    basic: undefined,
    normal: [],
    project: undefined
  },
  newTable: {},
  factorList: {},
  currentProjectQrcode: "",
  importTableModalVisible: false,
  currentTableStructure: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constant.CHANGE_ITEM:
      return state.setIn(action.target, action.value);
    case constant.ADD_BASIC: {
      let newBasic = state.getIn(["factorList", "basic"]);
      // 判断是否还有basic，没有直接不响应
      if (newBasic.size === 0) {
        return state;
      }
      let newTableBasic = state.getIn(["newTable", "basic"], new List());
      state.getIn(["currentSelect", "basic"]).forEach((item) => {
        let targetIndex = newBasic.findIndex((f) => f.get("id") === item);
        let targetBasic = newBasic.get(targetIndex);
        newBasic = newBasic.delete(targetIndex);
        newTableBasic = newTableBasic.push(targetBasic);
      });

      return state
        .setIn(["currentSelect", "basic"], undefined)
        .setIn(["factorList", "basic"], newBasic)
        .setIn(["newTable", "basic"], newTableBasic);
    }

    case constant.ADD_NORMAL: {
      let newNormal = state.getIn(["factorList", "normal"]);

      // 判断还有没有normal，没有则不响应
      if (
        newNormal.filter((f) => f.get("parent_id") === action.basicId).size ===
        0
      ) {
        return state;
      }
      let newTableNormal = state.getIn(["newTable", "normal"], new List());

      state
        .getIn(["currentSelect", "normal", action.basicId])
        .forEach((item) => {
          let targetIndex = newNormal.findIndex((f) => f.get("id") === item);
          let targetNormal = newNormal.get(targetIndex);
          newNormal = newNormal.delete(targetIndex);
          newTableNormal = newTableNormal.push(targetNormal);
        });

      return state
        .setIn(["currentSelect", "normal", action.basicId], undefined)
        .setIn(["factorList", "normal"], newNormal)
        .setIn(["newTable", "normal"], newTableNormal);
    }

    case constant.ADD_SUB: {
      let newSub = state.getIn(["factorList", "sub"]);

      // 判断还有没有sub，没有则不响应
      if (
        newSub.filter((f) => f.get("parent_id") === action.normalId).size === 0
      ) {
        return state;
      }
      let newTableSub = state.getIn(["newTable", "sub"], new List());

      state.getIn(["currentSelect", "sub", action.normalId]).forEach((item) => {
        let targetIndex = newSub.findIndex((f) => f.get("id") === item);
        let targetSub = newSub.get(targetIndex);
        newSub = newSub.delete(targetIndex);
        newTableSub = newTableSub.push(targetSub);
      });

      return state
        .setIn(["currentSelect", "sub", action.normalId], undefined)
        .setIn(["factorList", "sub"], newSub)
        .setIn(["newTable", "sub"], newTableSub);
    }

    case constant.REMOVE_SUB: {
      let newSub = state.getIn(["factorList", "sub"], new List());
      let newTableSub = state.getIn(["newTable", "sub"]);

      let targetIndex = newTableSub.findIndex(
        (f) => f.get("id") === action.subId
      );
      let target = newTableSub.get(targetIndex);

      newTableSub = newTableSub.delete(targetIndex);
      newSub = newSub.insert(0, target);

      return state
        .setIn(["factorList", "sub"], newSub)
        .setIn(["newTable", "sub"], newTableSub);
    }

    case constant.REMOVE_NORMAL: {
      // 不仅要移除Normal，还要移除sub
      let newFactorList = state.get("factorList");
      let newTable = state.get("newTable");

      let targetIndex = newTable
        .get("normal")
        .findIndex((f) => f.get("id") === action.normalId);
      let targetNormal = newTable.getIn(["normal", targetIndex], new List());

      newTable = newTable.deleteIn(["normal", targetIndex]);
      newFactorList = newFactorList.set(
        "normal",
        newFactorList.get("normal").insert(0, targetNormal)
      );

      // 移除相关因子
      let relativeSubs = newTable
        .get("sub", new List())
        .filter((f) => f.get("parent_id") === action.normalId);
      newTable = newTable.set(
        "sub",
        newTable
          .get("sub", new List())
          .filter((f) => f.get("parent_id") !== action.normalId)
      );
      newFactorList = newFactorList.set(
        "sub",
        newFactorList.get("sub").merge(relativeSubs)
      );

      return state.set("factorList", newFactorList).set("newTable", newTable);
    }

    case constant.REMOVE_BASIC: {
      // 不仅要移除basic, 还要移除normal和sub
      let newFactorList = state.get("factorList");
      let newTable = state.get("newTable");

      let targetIndex = newTable
        .get("basic")
        .findIndex((f) => f.get("id") === action.basicId);
      let targetBasic = newTable.getIn(["basic", targetIndex], new List());

      newTable = newTable.deleteIn(["basic", targetIndex]);
      newFactorList = newFactorList.set(
        "basic",
        newFactorList.get("basic").insert(0, targetBasic)
      );

      // 移除相关因素
      let relativeNormals = newTable
        .get("normal", new List())
        .filter((f) => f.get("parent_id") === action.basicId);
      newTable = newTable.set(
        "normal",
        newTable
          .get("normal", new List())
          .filter((f) => f.get("parent_id") !== action.basicId)
      );
      newFactorList = newFactorList.set(
        "normal",
        newFactorList.get("normal").merge(relativeNormals)
      );

      // 移除相关因子
      relativeNormals.forEach((normal) => {
        let relativeSubs = newTable
          .get("sub")
          .filter((f) => f.get("parent_id") === normal.get("id"));
        newTable = newTable.set(
          "sub",
          newTable
            .get("sub")
            .filter((f) => f.get("parent_id") !== normal.get("id"))
        );
        newFactorList = newFactorList.set(
          "sub",
          newFactorList.get("sub").merge(relativeSubs)
        );
      });

      return state.set("factorList", newFactorList).set("newTable", newTable);
    }
    default:
      return state;
  }
};
