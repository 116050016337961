import * as constant from "./constant";
import { fromJS } from "immutable";

const defaultState = fromJS({
  collapsed: false,
  pageLoading: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constant.CHANGE_ITEM:
      return state.setIn(action.target, action.value);
    default:
      return state;
  }
};
