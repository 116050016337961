import React from "react";

import { List } from "immutable";
import { connect } from "react-redux";
import { action } from "store/Project";

import { Modal } from "antd";
import echarts from "echarts";

class StructureModal extends React.Component {
  chartRef = React.createRef();
  state = {
    firstInit: false
  };

  initChart() {
    this.setState({ firstInit: true });
    let chart = echarts.init(this.chartRef.current, {
      renderer: "svg"
    });
    window.onresize = function () {
      setTimeout(function () {
        chart.resize();
      }, 50);
    };

    this.loadChart();
  }

  loadChart() {
    let chart = echarts.getInstanceByDom(this.chartRef.current);
    chart.clear();

    const currentTableStructure = this.props.currentTableStructure.toJS();

    let data = {
      name: currentTableStructure.title,
      children: currentTableStructure.basic?.map((basic) => ({
        name:
          basic.type === 1
            ? basic.name
            : `${basic.name}（${basic.minimum}~${basic.maximum}）`,
        children: currentTableStructure.normal
          ?.filter((item) => item.parent_id === basic.id)
          .map((normal) => ({
            name: normal.name,
            itemStyle: {
              borderColor: normal.status === 0 ? "#7ebc59" : "#c23531"
            },
            children: currentTableStructure.sub
              ?.filter((item) => item.parent_id === normal.id)
              .map((sub) => ({
                name: sub.name
              }))
          }))
      }))
    };

    chart.setOption({
      series: [
        {
          type: "tree",

          data: [data],

          top: "1%",
          left: "20%",
          bottom: "1%",
          right: "20%",

          symbolSize: 12,

          label: {
            position: "bottom",
            fontSize: 16,
            fontFamily: "Microsoft YaHei",
            fontWeight: 'bold'
          },

          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left"
            }
          },
          initialTreeDepth: 4,
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750
        }
      ]
    });
  }

  componentDidUpdate(nextProps, nextState) {
    // 需要modal显示
    if (this.props.visible) {
      // 没有初始化
      if (!this.state.firstInit) {
        setTimeout(() => this.initChart(), 30);
      }

      if (
        nextProps.currentTableStructure !== this.props.currentTableStructure &&
        this.state.firstInit
      ) {
        setTimeout(() => this.loadChart(), 30);
      }
    }
  }

  render() {
    return (
      <Modal
        width="90vw"
        title="评分表结构"
        visible={this.props.visible}
        onOk={this.props.closeModal}
        onCancel={this.props.closeModal}
        style={{ top: "2vh" }}
        footer={null}
      >
        备注：绿色圈代表不用填，默认100分
        <div ref={this.chartRef} style={{ height: "82vh" }}></div>
      </Modal>
    );
  }
}

const STP = (state) => {
  return {
    visible: state.getIn(["project", "modal", "structure"]),
    currentTableStructure:
      state.getIn(["project", "currentTableStructure"]) || new List()
  };
};

const DTP = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(action.changeItem(["modal", "structure"], false));
    }
  };
};

export default connect(STP, DTP)(StructureModal);
