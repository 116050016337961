import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Modal, Select, List, Button, message } from "antd";

import { API } from "utils/api";
import { Post } from "utils/request";
import { fromJS } from "immutable";

class ImportTableModel extends React.Component {
  render() {
    return (
      <Modal
        visible={this.props.importTableModalVisible}
        onCancel={this.props.closeImportTableModal}
        title="导入评分表"
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="请选择项目"
          style={{ width: "100%" }}
          value={this.props.currentSelect.get("project")}
          onChange={(value) => this.props.changeCurrectSelect(value)}
        >
          {this.props.projectList.map((project) => (
            <Select.Option
              key={`project${project.get("id")}`}
              value={project.get("id")}
            >
              {project.get("title")}
            </Select.Option>
          ))}
        </Select>
        {this.props.currentSelect.get("project") ? (
          <List
            bordered
            style={{ marginTop: "1em" }}
            dataSource={this.props.projectList
              .find(
                (i) => i.get("id") === this.props.currentSelect.get("project")
              )
              .get("tables")
              .toJS()}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    onClick={() => {
                      Post(`${API.TABLE_COPY}`, {
                        table_id: item.id,
                        project_id: this.props.match.params.id
                      })
                        .then((res) => {
                          let newProject = this.props.project.set(
                            "tables",
                            this.props.project.get("tables").push(fromJS(res))
                          );
                          this.props.changeProject(newProject);
                          message.success("导入成功");
                          this.props.closeImportTableModal();
                        })
                        .catch((err) => {
                          message.error(err.response.data.msg);
                        });
                    }}
                  >
                    导入
                  </Button>
                ]}
              >
                {item.title}
              </List.Item>
            )}
          />
        ) : null}
      </Modal>
    );
  }
}

const STP = (state) => {
  return {
    project: state.getIn(["project", "project"]),
    projectList: state.getIn(["project", "projectList"]),
    currentSelect: state.getIn(["project", "currentSelect"]),
    importTableModalVisible: state.getIn(["project", "importTableModalVisible"])
  };
};

const DTP = (dispatch) => {
  return {
    changeProject: (newProject) => {
      dispatch(action.changeItem(["project"], newProject));
    },
    closeImportTableModal: () => {
      dispatch(action.changeItem(["importTableModalVisible"], false));
    },
    changeCurrectSelect: (projectId) => {
      dispatch(action.changeItem(["currentSelect", "project"], projectId));
    }
  };
};

export default withRouter(connect(STP, DTP)(ImportTableModel));
