import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MarkLayout from "layout/MarkLayout";
import { List, Avatar, Button } from "antd";
import { action } from "store/Mark";

/**
 * 评分表页面
 */
class TaskPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadProject(this.props.match.params.id);
  }

  render() {
    return (
      <MarkLayout
        title={this.props.projectInfo.get("title")}
        description={
          <span>
            当前轮次： 第{this.props.projectInfo.get("status")}轮 <br />
            {this.props.userInfo.get("name")}专家您好！ <br />
            共有 {this.props.projectInfo.get("tables").size}
            张表需要您打分，目前剩余
            {this.props.projectInfo.get("tables").size -
              this.props.projectInfo
                .get("tables")
                .filter((i) => i.get("status")).size}
            张未评分。
            <br />
            注：您可以对已评分的表进行修改。
          </span>
        }
        content={
          <List
            itemLayout="horizontal"
            dataSource={this.props.projectInfo.get("tables")}
            renderItem={(item) => (
              <List.Item
                actions={[
                  item.get("status") ? (
                    <Button
                      key="1"
                      type="primary"
                      className="button-color-green"
                      onClick={() => {
                        this.props.loadScoreTable(
                          item.get("id"),
                          this.props.projectInfo
                        );
                      }}
                    >
                      已评分
                    </Button>
                  ) : (
                    <Button
                      key="2"
                      type="primary"
                      onClick={() => {
                        this.props.loadScoreTable(
                          item.get("id"),
                          this.props.projectInfo
                        );
                      }}
                    >
                      评分
                    </Button>
                  )
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size="large"
                      className="avatar"
                      style={{ background: "#1890ff" }}
                    >
                      {item.get("title")[0]}
                    </Avatar>
                  }
                  title={item.get("title")}
                  description={`创建时间：${item.get("create_time")}`}
                />
              </List.Item>
            )}
          />
        }
      />
    );
  }
}

const STP = (state) => {
  return {
    userInfo: state.getIn(["mark", "userInfo"]),
    projectInfo: state.getIn(["mark", "projectInfo"])
  };
};

const DTP = (dispatch) => {
  return {
    loadProject: (projectId) => {
      dispatch(action.loadProject(projectId));
    },
    loadScoreTable: (tableId, projectInfo) => {
      dispatch(action.loadScoreTable(tableId, projectInfo));
    }
  };
};

export default withRouter(connect(STP, DTP)(TaskPage));
