let domain = "";

if (process.env.NODE_ENV === "production") {
  domain = "http://score.gdzypg.com/api";
} else {
  domain = "http://score.gdzypg.com/api";
}

const API = {
  FACTOR_BASIC: domain + "/factor/basic",
  FACTOR_NORMAL: domain + "/factor/normal",
  FACTOR_SUB: domain + "/factor/sub",
  FACTOR_LIST: domain + "/factor/list",
  PROJECT_LIST: domain + "/project_list ",
  PROJECT_BASE: domain + "/project",
  PROJECT_RESULT: domain + "/project/result",
  PROJECT_QRCODE: domain + "/project/qrcode",
  TABLE_BASE: domain + "/table",
  TABLE_COPY: domain + "/table/copy",
  TABLE_SCORED: domain + "/table/scored",
  SCORER: domain + "/scorer",
  SCORE: domain + "/score",

  COMMIT_EXCEL_SCORE: domain + "/commit_excel_score",
  COMMIT_SCORE: domain + "/score/commit",

  LOGIN: domain + "/login",
  CHECK_LOGIN: domain + "/check_login",
  LOGOUT: domain + "/logout"
};

export { API, domain };
