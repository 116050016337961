import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";
import { Select, Row, Col, Button, Checkbox } from "antd";

import { List } from "immutable";

class NormalStep extends React.Component {
  render() {
    const selectedBasics = this.props.newTable.get("basic", new List());

    return (
      <div>
        {selectedBasics.map((basic, basicIndex) => {
          const normals = this.props.factorList
            .get("normal", new List())
            .filter((f) => f.get("parent_id") === basic.get("id"));
          const selectedNormals = this.props.newTable
            .get("normal", new List())
            .filter((f) => f.get("parent_id") === basic.get("id"));
          return (
            <div className="normal-item" key={`baisc${basic.get("id")}`}>
              <Row gutter={15}>
                <Col span={4}>{basic.get("name")}</Col>
                <Col span={10}>
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="请选择因素"
                    style={{ width: "100%" }}
                    value={this.props.currentSelect.getIn([
                      "normal",
                      basic.get("id")
                    ])}
                    onChange={(value) =>
                      this.props.changeCurrectSelect(basic.get("id"), value)
                    }
                  >
                    {normals.map((normal) => (
                      <Select.Option
                        key={`normal${normal.get("id")}`}
                        value={normal.get("id")}
                      >
                        {normal.get("name")}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={4}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => this.props.addNormal(basic.get("id"))}
                  >
                    添加
                  </Button>
                </Col>
              </Row>

              {selectedNormals.map((selectedNormal, selectedNormalIndex) => (
                <Row
                  gutter={15}
                  style={{ marginTop: "1em" }}
                  key={`normal${selectedNormal.get("id")}`}
                >
                  <Col span={8}>
                    {selectedNormalIndex + 1}. {selectedNormal.get("name")}
                  </Col>
                  <Col span={4}>
                    <Checkbox
                      style={{ marginLeft: 10 }}
                      onChange={(e) => {
                        let newNormal = selectedNormal.set(
                          "status",
                          e.target.checked ? 0 : 1
                        );
                        let normalIndex = this.props.newTable
                          .get("normal")
                          .findIndex(
                            (f) => f.get("id") === newNormal.get("id")
                          );
                        this.props.changeNormal(normalIndex, newNormal);
                      }}
                      checked={selectedNormal.get("status", 1) !== 1}
                    >
                      不用填（100分）
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="danger"
                      onClick={() =>
                        this.props.removeNormal(selectedNormal.get("id"))
                      }
                    >
                      移除
                    </Button>
                  </Col>
                </Row>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}

const STP = (state) => {
  return {
    factorList: state.getIn(["project", "factorList"]),
    newTable: state.getIn(["project", "newTable"]),
    currentSelect: state.getIn(["project", "currentSelect"])
  };
};

const DTP = (dispatch) => {
  return {
    changeCurrectSelect: (basicId, value) => {
      dispatch(action.changeItem(["currentSelect", "normal", basicId], value));
    },
    addNormal: (basicId) => {
      dispatch(action.addNormal(basicId));
    },
    changeNormal: (normalIndex, newNormal) => {
      dispatch(
        action.changeItem(["newTable", "normal", normalIndex], newNormal)
      );
    },
    removeNormal: (normalId) => {
      dispatch(action.removeNormal(normalId));
    }
  };
};

export default connect(STP, DTP)(NormalStep);
