import React from "react";
import { connect } from "react-redux";
import { constant, actionCreator } from "../store";

import { Button, Tag } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { StyledNormalFactor } from "../style";

import { Map } from "immutable";

class NormalFactorPanel extends React.Component {
  render() {
    const {
      normal,
      factorList,
      addFactorModal,
      updateFactorModal
    } = this.props;

    return (
      <StyledNormalFactor>
        <div className="header">
          <span
            onClick={() =>
              updateFactorModal("更新因素", constant.UPDATE_NORMAL, normal)
            }
          >
            {normal.get("name")}
          </span>
          <DeleteOutlined
            style={{ marginLeft: 10 }}
            onClick={() =>
              updateFactorModal("删除因素", constant.DELETE_NORMAL, normal)
            }
          />
        </div>

        <div className="content">
          {factorList
            .get("sub")
            .filter((f) => f.get("parent_id") === normal.get("id"))
            .map((sub) => (
              <Tag
                closable
                key={sub.get("id")}
                onClick={() =>
                  updateFactorModal("更新因子", constant.UPDATE_SUB, sub)
                }
                onClose={(e) => {
                  e.preventDefault();
                  updateFactorModal("删除因子", constant.DELETE_SUB, sub);
                }}
                style={{ cursor: "pointer" }}
              >
                {sub.get("name")}
              </Tag>
            ))}

          <Button
            ghost
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => addFactorModal(normal.get("id"))}
            style={{ marginTop: 10 }}
          >
            添加
          </Button>
        </div>
      </StyledNormalFactor>
    );
  }
}

const mapSTP = (state) => {
  return {
    factorList: state.getIn(["factor", "factorList"])
  };
};

const mapDTP = (dispatch) => {
  return {
    addFactorModal(normalId) {
      dispatch(
        actionCreator.changeModal(
          new Map({
            show: true,
            title: "添加因子",
            action: constant.ADD_SUB
          })
        )
      );
      dispatch(actionCreator.changeItem("factorParentId", normalId));
    },
    updateFactorModal(title, action, factor) {
      dispatch(
        actionCreator.changeModal(
          new Map({
            show: true,
            title: title,
            action: action,
            inputValue: factor.get("name")
          })
        )
      );
      dispatch(actionCreator.changeItem("factorParentId", factor.get("id")));
    }
  };
};

export default connect(mapSTP, mapDTP)(NormalFactorPanel);
