import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { action } from "store/Project";

import styled from "styled-components";
import AdminLayout from "layout/AdminLayout";
import ResultExportModal from "./components/ResultExportModal";
import {
  Descriptions,
  Button,
  List,
  Tabs,
  Typography,
  Avatar,
  Select,
  Result
} from "antd";
import { ExportOutlined } from "@ant-design/icons";
import ResultTable from "./ResultTable";
import { getShortName } from "utils/user";
import { List as ImmutableList } from "immutable";

import { getProjectStatusTag } from "utils/project";
import { domain } from "utils/api";

const StyledProjectResultPage = styled.div`
  .scorers {
    margin-bottom: 3em;
  }

  .userinfo {
    margin-top: 1em;
  }

  .signature {
    padding: 10px;
    border: 1px solid #eee;
  }

  .basic {
    padding-left: 66px;
  }
`;

class ProjectResultPage extends React.PureComponent {
  componentWillMount() {
    this.props.loadProject(this.props.match.params.id, 1);
  }

  render() {
    const { project } = this.props;

    return (
      <AdminLayout
        title={project.get("title")}
        navSelected={["/"]}
        onBack={() => window.history.back()}
        buttonGroup={[
          <Button
            key="export"
            type="primary"
            style={{ display: "inline-block" }}
            icon={<ExportOutlined />}
            onClick={() => this.props.showResultExportModal(project.get("id"))}
          >
            导出结果
          </Button>,
          <Select
            key="rounds"
            defaultValue={1}
            style={{ width: 120 }}
            onChange={(e) =>
              this.props.loadProject(this.props.match.params.id, parseInt(e))
            }
          >
            <Select.Option value={1} key="1">
              第一轮
            </Select.Option>
            <Select.Option value={2} key="2">
              第二轮
            </Select.Option>
          </Select>
        ]}
        description={
          <Descriptions size="small" column={3}>
            <Descriptions.Item label="创建人">
              {project.getIn(["creator", "username"])}
            </Descriptions.Item>
            <Descriptions.Item label="当前状态">
              {getProjectStatusTag(project.get("status"))}
            </Descriptions.Item>
            <Descriptions.Item label="创建时间">
              {project.get("create_time")}
            </Descriptions.Item>
            <Descriptions.Item label="修改时间">
              {project.get("update_time")}
            </Descriptions.Item>
            <Descriptions.Item label="备注">无</Descriptions.Item>
          </Descriptions>
        }
        content={
          <StyledProjectResultPage>
            <ResultExportModal />

            <Tabs defaultActiveKey="1">
              {project.get("tables").map((table) => (
                <Tabs.TabPane tab={table.get("title")} key={table.get("id")}>
                  {/* 评分人员 */}
                  {table.get("scorers", new ImmutableList()).size === 0 ? (
                    <Result
                      status="404"
                      title="没有数据"
                      subTitle="没有任何评分数据"
                    />
                  ) : (
                    <>
                      <Typography.Title level={4}>已评分人员</Typography.Title>
                      <List
                        itemLayout="vertical"
                        size="small"
                        pagination={{
                          pageSize: 3,
                          total: table.get("scorers", new ImmutableList()).size
                        }}
                        dataSource={table
                          .get("scorers", new ImmutableList())
                          .toJS()}
                        renderItem={(item) => (
                          <List.Item
                            key={item.name}
                            extra={
                              <img
                                width={150}
                                alt="签名"
                                src={`${domain}/${item.signature}`}
                              />
                            }
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  size={36}
                                  style={{ background: "#007cfa" }}
                                >
                                  {getShortName(item.name)}
                                </Avatar>
                              }
                              title={item.name}
                            />
                            <Descriptions>
                              <Descriptions.Item label="单位">
                                {item.organization}
                              </Descriptions.Item>
                              <Descriptions.Item label="手机号">
                                {item.phone}
                              </Descriptions.Item>
                            </Descriptions>
                          </List.Item>
                        )}
                      />

                      {/* 评分结果 */}
                      <Typography.Title level={4} style={{ marginTop: "1em" }}>
                        打分结果
                      </Typography.Title>
                      <ResultTable table={table} />
                    </>
                  )}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </StyledProjectResultPage>
        }
      />
    );
  }
}

const STP = (state) => {
  return {
    project: state.getIn(["project", "project"])
  };
};

const DTP = (dispatch) => {
  return {
    loadProject: (projectId, rounds) => {
      dispatch(action.loadProject(projectId, rounds));
    },
    showResultExportModal: (projectId) => {
      dispatch(action.changeItem(["modal", "result"], true));
      dispatch(action.changeItem(["currentSelect", "project"], projectId));
    }
  };
};

export default withRouter(connect(STP, DTP)(ProjectResultPage));
