import * as constant from "./constant";
import * as layoutConstant from "../Layout/constant";

import { API } from "utils/api";
import { fromJS } from "immutable";
import { Get, Put } from "utils/request";
import { Post } from "utils/request";
import { message } from "antd";

export const changeItem = (target, value) => ({
  type: constant.CHANGE_ITEM,
  target: target,
  value: value
});

export const addBasic = () => ({
  type: constant.ADD_BASIC
});

export const addNormal = (basicId) => ({
  type: constant.ADD_NORMAL,
  basicId: basicId
});

export const addSub = (normalId) => ({
  type: constant.ADD_SUB,
  normalId: normalId
});

export const removeBasic = (basicId) => ({
  type: constant.REMOVE_BASIC,
  basicId: basicId
});

export const removeNormal = (normalId) => ({
  type: constant.REMOVE_NORMAL,
  normalId: normalId
});

export const removeSub = (subId) => ({
  type: constant.REMOVE_SUB,
  subId: subId
});

export const initData = () => {
  return (dispatch) => {
    dispatch({
      type: layoutConstant.CHANGE_ITEM,
      target: ["pageLoading"],
      value: true
    });
    Get(API.PROJECT_LIST)
      .then((res) => {
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["projectList"],
          value: fromJS(res)
        });
      })
      .catch((err) => {})
      .then(() => {
        dispatch({
          type: layoutConstant.CHANGE_ITEM,
          target: ["pageLoading"],
          value: false
        });
      });
  };
};

export const createProject = (title, projectList, history) => {
  return (dispatch) => {
    Post(API.PROJECT_BASE, { title })
      .then((res) => {
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["projectList"],
          value: projectList.insert(0, fromJS(res))
        });
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["modal", "createProject"],
          value: false
        });
        return Promise.resolve(res);
      })
      .then((res) => {
        message.success("创建成功！");
        history.push(`/project/${res.id}`);
      });
  };
};

export const updateProject = (index, target, projectId) => {
  return (dispatch) => {
    Put(`${API.PROJECT_BASE}/${projectId}`, { status: target }).then((res) => {
      dispatch({
        type: constant.CHANGE_ITEM,
        target: ["projectList", index, "status"],
        value: target
      });
    });
  };
};

export const loadProject = (projectId, rounds) => {
  return (dispatch) => {
    dispatch({
      type: layoutConstant.CHANGE_ITEM,
      target: ["pageLoading"],
      value: true
    });
    Get(`${API.PROJECT_RESULT}/${projectId}`, {
      rounds: rounds,
      type: "json"
    })
      .then((res) => {
        dispatch({
          type: constant.CHANGE_ITEM,
          target: ["project"],
          value: fromJS(res)
        });
      })
      .catch((err) => {})
      .then(() => {
        dispatch({
          type: layoutConstant.CHANGE_ITEM,
          target: ["pageLoading"],
          value: false
        });
      });
  };
};
