import axios from "axios";
import { message } from "antd";
import { API } from "utils/api";

export const checkLogin = (history) => {
  return new Promise((resolve, reject) => {
    Get(API.CHECK_LOGIN);
  });
};

const instance = axios.create({
  //创建axios实例，在这里可以设置请求的默认配置
  timeout: 10000, // 设置超时时间10s
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV === "production"
      ? "http://139.159.246.152:8080"
      : "http://localhost:8080"
});

let httpCode = {
  400: "请求参数错误",
  401: "权限不足, 请重新登录",
  403: "服务器拒绝本次访问",
  404: "请求资源未找到",
  500: "内部服务器错误",
  501: "服务器不支持该请求中使用的方法",
  502: "网关错误",
  504: "网关超时"
};

/** 添加响应拦截器  **/
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      message.error(response.data.message);
      return Promise.reject(response.data.message);
    }
  },
  (error) => {
    if (error.response) {
      // 根据请求失败的http状态码去给用户相应的提示
      let tips =
        error.response.status in httpCode
          ? httpCode[error.response.status]
          : error.response.data.message;
      if (error.response.status === 401) {
        // 状态401是未登录，跳转到登录，具体情况具体修改
        message.error(tips);
        window.location.href = "/login";
      } else if (error.response.status !== 200) {
        if (typeof error.response.data.msg === "string") {
          message.error(error.response.data.msg);
        } else {
          // 数组
          for (let key in error.response.data.msg) {
            message.error(error.response.data.msg[key].join(", "));
          }
        }
      }
      return Promise.reject(error);
    } else {
      // message.error("请求超时, 请刷新重试");
      return Promise.reject(new Error("请求超时, 请刷新重试"));
    }
  }
);

export const Get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "GET",
      url,
      params,
      ...config
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "POST",
      url,
      data,
      ...config
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// 发送表单
export const PostForm = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "POST",
      url,
      data,
      ...config,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      transformRequest: [
        function (data) {
          let ret = "";
          for (let it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          return ret;
        }
      ]
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Put = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "PUT",
      url,
      data,
      ...config
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Delete = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "DELETE",
      url,
      params,
      ...config
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
