import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { action } from "store/Login";
import config from '../../config'

import { StyledLoginPage } from "./style";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Input, Form, Button, Checkbox, Row, Col } from "antd";

class LoginPage extends React.Component {
  render() {
    const { username, password, remember, loading, login } = this.props;

    return (
      <StyledLoginPage>
        <div className="header">
          <div className="title">后台登录 - {config.title}</div>
          <div className="state">{config.en_title} System - Login</div>
        </div>

        <Row>
          <Col
            xxl={{ span: 6, offset: 9 }}
            xl={{ span: 8, offset: 8 }}
            lg={{ span: 12, offset: 6 }}
            md={{ span: 12, offset: 6 }}
            xm={{ span: 12, offset: 6 }}
            xs={{ span: 20, offset: 2 }}
          >
            <Form
              fields={[
                { name: ["username"], value: username },
                { name: ["password"], value: password },
                { name: ["remember"], value: remember }
              ]}
              onValuesChange={(changedValues, allValues) => {
                for (let key in changedValues) {
                  this.props.changeItem([key], changedValues[key]);
                }
              }}
              onFinish={(data) => login(data, this.props.history)}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: "请输入用户名" }]}
              >
                <Input
                  autoFocus
                  allowClear
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="请输入登录用户名"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password
                  allowClear
                  size="large"
                  prefix={<LockOutlined />}
                  placeholder="请输入密码"
                />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>记住我</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  style={{ width: "100%" }}
                  htmlType="submit"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </StyledLoginPage>
    );
  }
}

const STP = (state) => {
  return {
    username: state.getIn(["login", "username"]),
    password: state.getIn(["login", "password"]),
    remember: state.getIn(["login", "remember"]),
    loading: state.getIn(["login", "loading"])
  };
};

const DTP = (dispatch) => {
  return {
    changeItem: (target, value) => {
      dispatch(action.changeItem(target, value));
    },
    login: (data, history) => {
      dispatch(action.login(data, history));
    }
  };
};

export default withRouter(connect(STP, DTP)(LoginPage));
