import React from "react";
import { Table } from "antd";

const SubTable = (props) => {
  const columns = [
    {
      title: "因子层",
      dataIndex: "sub",
      key: "sub",
      width: 280
    },
    {
      title: "评分人",
      align: "center",
      children: props.table
        .get("scorers")
        .map((item) => ({
          title: item.get("name"),
          dataIndex: item.get("id"),
          key: item.get("id"),
          align: "center"
        }))
        .toArray()
    },
    {
      title: "均值",
      dataIndex: "mean",
      key: "mean",
      width: 100
    },
    {
      title: "方差",
      dataIndex: "var",
      key: "var",
      width: 100
    },
    {
      title: "标准差",
      dataIndex: "std",
      key: "std",
      width: 100
    }
  ];

  const dataSource = props.table
    .get("sub")
    .filter(
      (i) => i.get("parent_id") === parseInt(props.normalKey.split("-")[1])
    )
    .map((item) => {
      let data = {
        key: `sub-${item.get("id")}`,
        sub: item.get("name"),
        mean: item.get("mean"),
        var: item.get("var"),
        std: item.get("std")
      };

      // 填充用户数据
      props.table
        .get("scorers")
        .map(
          (scorer, scorerIndex) =>
            (data[scorer.get("id")] = item.getIn(["values", scorerIndex]))
        );

      return data;
    })
    .toArray();

  return (
    <Table
      showHeader={false}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  );
};

const NormalTable = (props) => {
  const columns = [
    {
      title: "派生因素层",
      dataIndex: "normal",
      key: "normal",
      width: 280
    },
    {
      title: "评分人",
      align: "center",
      children: props.table
        .get("scorers")
        .map((item) => ({
          title: item.get("name"),
          dataIndex: item.get("id"),
          key: item.get("id"),
          align: "center"
        }))
        .toArray()
    },
    {
      title: "均值",
      dataIndex: "mean",
      key: "mean",
      width: 100
    },
    {
      title: "方差",
      dataIndex: "var",
      key: "var",
      width: 100
    },
    {
      title: "标准差",
      dataIndex: "std",
      key: "std",
      width: 100
    }
  ];

  const dataSource = props.table
    .get("normal")
    .filter(
      (i) => i.get("parent_id") === parseInt(props.basicKey.split("-")[1])
    )
    .map((item) => {
      let data = {
        key: `normal-${item.get("id")}`,
        normal: `${item.get("name")}${
          item.get("status", 1) === 0 ? "（不填）" : ""
        }`,
        mean: item.get("mean"),
        var: item.get("var"),
        std: item.get("std")
      };

      // 填充用户数据
      props.table
        .get("scorers")
        .map(
          (scorer, scorerIndex) =>
            (data[scorer.get("id")] = item.getIn(["values", scorerIndex]))
        );

      return data;
    })
    .toArray();

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      showHeader={false}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) =>
          props.table
            .get("sub")
            .filter(
              (i) => i.get("parent_id") === parseInt(record.key.split("-")[1])
            ).size === 0 ? null : (
            <SubTable normalKey={record.key} table={props.table} />
          )
      }}
      pagination={false}
    />
  );
};

const BasicTable = (props) => {
  const columns = [
    {
      title: "因素名",
      dataIndex: "basic",
      key: "basic",
      className: "basic",
      width: 330
    },
    {
      title: `评分人（${props.table.get("scorers").size}人）`,
      ellipsis: true,
      align: "center",
      children: props.table
        .get("scorers")
        .map((item) => ({
          title: item.get("name"),
          dataIndex: item.get("id"),
          key: item.get("id"),
          align: "center"
        }))
        .toArray()
    },
    {
      title: "均值",
      dataIndex: "mean",
      key: "mean",
      width: 100
    },
    {
      title: "方差",
      dataIndex: "var",
      key: "var",
      width: 100
    },
    {
      title: "标准差",
      dataIndex: "std",
      key: "std",
      width: 100
    }
  ];

  const dataSource = props.table
    .get("basic")
    .map((item) => {
      let data = {
        key: `basic-${item.get("id")}`,
        basic: item.get("name"),
        mean: item.get("mean"),
        var: item.get("var"),
        std: item.get("std")
      };

      // 填充用户数据
      props.table
        .get("scorers")
        .map(
          (scorer, scorerIndex) =>
            (data[scorer.get("id")] = item.getIn(["values", scorerIndex]))
        );

      return data;
    })
    .toArray();

  return (
    <Table
      bordered
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      tableLayout="fixed"
      expandable={{
        defaultExpandAllRows: true,
        expandedRowRender: (record, index, indent, expanded) =>
          props.table
            .get("normal")
            .filter(
              (i) => i.get("parent_id") === parseInt(record.key.split("-")[1])
            ).size === 0 ? null : (
            <NormalTable basicKey={record.key} table={props.table} />
          )
      }}
    />
  );
};

class ResultTable extends React.PureComponent {
  render() {
    return <BasicTable {...this.props} />;
  }
}

export default ResultTable;
