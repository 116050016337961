import React from "react";
import { action } from "store/Mark";
import { connect } from "react-redux";

import { Result, Button } from "antd";

class OkPage extends React.PureComponent {
  render() {
    return (
      <Result
        status="success"
        title="感谢您的评分！"
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => this.props.changeCurrentPage(1)}
          >
            返回任务列表，继续评分
          </Button>
        ]}
      />
    );
  }
}

const STP = (state) => {
  return {
    currentPage: state.getIn(["mark", "currentPage"])
  };
};

const DTP = (dispatch) => {
  return {
    changeCurrentPage: (page) => {
      dispatch(action.changeItem(["currentPage"], page));
    }
  };
};

export default connect(STP, DTP)(OkPage);
