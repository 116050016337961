import styled from "styled-components";

export const StyledProjectPage = styled.div`
  .project-item {
    .project-description div {
      margin-bottom: 0.6em;
    }

    .overview {
      margin-top: 1em;
      padding-top: 1em;
      border-top: 1px dashed #e8e8e8;

      .o-item {
        display: inline-block;
        text-align: center;
        width: 30%;

        .label {
          color: #ababab;
        }

        .number {
          font-size: 1.8em;
          color: #000;
          font-weight: bold;

          span {
            font-size: 0.5em;
            color: #ababab;
            font-weight: 500;
          }
        }
      }

      .divider {
        height: 3em;
      }
    }
  }
`;
