import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { action } from "store/Project";
import { action as layoutAction } from "store/Layout";

import { Avatar, List, Button, Popconfirm, message } from "antd";
import { StyledCreateProjectPage } from "./style";

import AdminLayout from "layout/AdminLayout";
import ButtonGroup from "./components/ButtonGroup";
import StructureModal from "./components/StructureModal";
import CreateTableModal from "./components/CreateTableModal";
import ImportTableModel from "./components/ImportTableModal";

import { API } from "utils/api";
import { Get, Post, Delete } from "utils/request";
import { fromJS, List as ImmutableList } from "immutable";

class ProjectDetailPage extends React.Component {
  componentDidMount() {
    this.props.initProject(this.props.match.params.id);
  }
  render() {
    const { project } = this.props;
    return (
      <AdminLayout
        title={project.get("title")}
        navSelected={["/"]}
        onBack={() => window.history.back()}
        buttonGroup={[<ButtonGroup key="1" />]}
        content={
          <StyledCreateProjectPage>
            <StructureModal />
            <CreateTableModal />
            <ImportTableModel />

            <List
              bordered
              dataSource={project.get("tables", new ImmutableList()).toJS()}
              renderItem={(item, index) => (
                <List.Item
                  key={item.id}
                  actions={[
                    <Button
                      key="structure"
                      type="primary"
                      onClick={() => {
                        this.props.showStructureModal(item.id);
                      }}
                    >
                      查看结构
                    </Button>,
                    <Button
                      type="primary"
                      key="update"
                      onClick={() => {
                        // 先判断是否已经有评分，有评分不允许修改
                        Get(`${API.TABLE_SCORED}/${item.id}`).then((res) => {
                          if (res) {
                            message.warning("当前评分表已有评分，不允许修改");
                          } else {
                            Promise.all([
                              Get(API.FACTOR_LIST),
                              Get(`${API.TABLE_BASE}/${item.id}`)
                            ]).then((values) => {
                              let factorList = values[0];
                              let tableData = values[1];

                              const basicIds = tableData.basic.map((f) => f.id);
                              const normalIds = tableData.normal.map(
                                (f) => f.id
                              );
                              const subIds = tableData.sub.map((f) => f.id);

                              // 去掉所有存在的basic normal 和sub
                              factorList.basic = factorList.basic.filter(
                                (f) => basicIds.indexOf(f.id) === -1
                              );
                              factorList.normal = factorList.normal.filter(
                                (f) => normalIds.indexOf(f.id) === -1
                              );
                              factorList.sub = factorList.sub.filter(
                                (f) => subIds.indexOf(f.id) === -1
                              );

                              this.props.loadTable(
                                fromJS(tableData),
                                fromJS(factorList)
                              );
                            });

                            this.props.showCreateTableModal();
                          }
                        });
                      }}
                    >
                      修改
                    </Button>,
                    <Button
                      type="primary"
                      key="copy"
                      onClick={() => {
                        Post(`${API.TABLE_COPY}`, {
                          table_id: item.id,
                          project_id: this.props.match.params.id
                        })
                          .then((res) => {
                            let newProject = this.props.project.set(
                              "tables",
                              this.props.project.get("tables").push(fromJS(res))
                            );
                            this.props.changeProject(newProject);
                            message.success("复制成功");
                            this.props.closeCreateTableModal();
                          })
                          .catch((err) => {
                            message.error(err.response.data.msg);
                          });
                      }}
                    >
                      复制
                    </Button>,
                    <Popconfirm
                      placement="bottomRight"
                      title="确定删除该评分表吗？"
                      onConfirm={() => {
                        let newProjectTables = project
                          .get("tables", new ImmutableList())
                          .delete(index);
                        this.props.deleteTable(item.id, newProjectTables);
                      }}
                    >
                      <Button type="danger" key="delete">
                        删除
                      </Button>
                    </Popconfirm>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar size="large" style={{ background: "#1890ff" }}>
                        {item.title[0]}
                      </Avatar>
                    }
                    description={`创建时间：${item.create_time}`}
                    title={`${index + 1}. ${item.title}`}
                  />
                </List.Item>
              )}
            />
          </StyledCreateProjectPage>
        }
      />
    );
  }
}

const STP = (state) => {
  return {
    project: state.getIn(["project", "project"]),
    factorList: state.getIn(["project", "factorList"])
  };
};

const DTP = (dispatch) => {
  return {
    showStructureModal: (tableId) => {
      dispatch(action.changeItem(["modal", "structure"], true));
      Get(`${API.TABLE_BASE}/${tableId}`).then((res) => {
        dispatch(action.changeItem(["currentTableStructure"], fromJS(res)));
      });
    },
    changeProject: (newProject) => {
      dispatch(action.changeItem(["project"], newProject));
    },
    closeCreateTableModal: () => {
      dispatch(action.changeItem(["createTableModalVisible"], false));
    },
    loadTable: (newTable, factorList) => {
      dispatch(action.changeItem(["newTable"], newTable));
      dispatch(action.changeItem(["factorList"], factorList));
      dispatch(action.changeItem(["currentStep"], 0));
      dispatch(action.changeItem(["currentSelect"], new Map()));
    },
    showCreateTableModal: () => {
      dispatch(action.changeItem(["createTableModalVisible"], true));
    },
    initProject: (projectId) => {
      dispatch(layoutAction.changeItem(["pageLoading"], true));
      Get(`${API.PROJECT_BASE}/${projectId}`)
        .then((res) => {
          dispatch(action.changeItem(["project"], fromJS(res)));
        })
        .finally(() =>
          dispatch(layoutAction.changeItem(["pageLoading"], false))
        );
    },
    deleteTable: (tableId, newTables) => {
      dispatch(layoutAction.changeItem(["pageLoading"], true));
      Delete(`${API.TABLE_BASE}/${tableId}`)
        .then((res) => {
          dispatch(action.changeItem(["project", "tables"], newTables));
          message.success("删除成功");
        })
        .finally(() =>
          dispatch(layoutAction.changeItem(["pageLoading"], false))
        );
    }
  };
};

export default withRouter(connect(STP, DTP)(ProjectDetailPage));
