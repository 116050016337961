import React from "react";
import { connect } from "react-redux";
import { action } from "store/Project";

import { Row, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { StyledProjectPage } from "../style";

import AdminLayout from "layout/AdminLayout";
import CreateProjectModal from "./components/CreateProjectModal";

import QrcodeModal from "./components/QrcodeModal";
import ProjectItem from "./components/ProjectItem";

class ProjectPage extends React.Component {
  state = { searchText: "" };

  componentDidMount() {
    this.props.initData();
  }

  render() {
    let projectList = this.props.projectList.filter(
      (item) =>
        `${item.get("title")}_${item.get(["creator", "username"])}`.indexOf(
          this.state.searchText
        ) !== -1
    );

    return (
      <AdminLayout
        title="项目管理"
        buttonGroup={[
          <Input.Search
            key="1"
            placeholder="输入搜索关键字"
            onChange={(e) => this.setState({ searchText: e.target.value })}
            style={{ width: 300 }}
          />,
          <Button
            key="2"
            type="primary"
            style={{ display: "inline-block" }}
            icon={<PlusOutlined />}
            onClick={this.props.showBasicInfoModal}
          >
            创建项目
          </Button>
        ]}
        content={
          <StyledProjectPage>
            <CreateProjectModal />
            <QrcodeModal />

            <Row gutter={[15, 15]}>
              {projectList.map((item, index) => (
                <ProjectItem index={index} item={item} key={item.get("id")} />
              ))}
            </Row>
          </StyledProjectPage>
        }
      />
    );
  }
}

const STP = (state) => {
  return {
    projectList: state.getIn(["project", "projectList"])
  };
};

const DTP = (dispatch) => {
  return {
    initData: () => {
      dispatch(action.initData());
    },
    showBasicInfoModal: () => {
      dispatch(action.changeItem(["modal", "createProject"], true));
    }
  };
};

export default connect(STP, DTP)(ProjectPage);
