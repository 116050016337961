import { fromJS } from "immutable";
import * as constant from "./constant";

const defaultState = fromJS({
  factorList: {
    basic: [
      {
        id: 10,
        name: "测试"
      },
      {
        id: 7,
        name: "社会经济发展状况"
      },
      {
        id: 6,
        name: "宏观区位影响度"
      },
      {
        id: 5,
        name: "城镇规划"
      },
      {
        id: 4,
        name: "人口状况"
      },
      {
        id: 3,
        name: "基本设施状况"
      },
      {
        id: 2,
        name: "交通条件"
      },
      {
        id: 1,
        name: "繁华程度"
      }
    ],
    normal: [
      {
        id: 15,
        name: "测试",
        parent_id: 10
      },
      {
        id: 12,
        name: "人口密度",
        parent_id: 7
      },
      {
        id: 13,
        name: "经济产值",
        parent_id: 7
      },
      {
        id: 14,
        name: "耕地占比",
        parent_id: 7
      },
      {
        id: 10,
        name: "中心城镇",
        parent_id: 6
      },
      {
        id: 11,
        name: "产业园区、集中区、集散地",
        parent_id: 6
      },
      {
        id: 8,
        name: "道路规划",
        parent_id: 5
      },
      {
        id: 9,
        name: "商服用地规划",
        parent_id: 5
      },
      {
        id: 7,
        name: "人口密度",
        parent_id: 4
      },
      {
        id: 5,
        name: "基础设施完善度",
        parent_id: 3
      },
      {
        id: 6,
        name: "公用设施完备度",
        parent_id: 3
      },
      {
        id: 2,
        name: "道路通达度",
        parent_id: 2
      },
      {
        id: 3,
        name: "公交便捷度",
        parent_id: 2
      },
      {
        id: 4,
        name: "对外交通便利度",
        parent_id: 2
      },
      {
        id: 1,
        name: "商服繁华影响度",
        parent_id: 1
      }
    ],
    sub: [
      {
        id: 26,
        name: "测试",
        parent_id: 15
      },
      {
        id: 25,
        name: "ces",
        parent_id: 15
      },
      {
        id: 11,
        name: "供电",
        parent_id: 5
      },
      {
        id: 12,
        name: "供水",
        parent_id: 5
      },
      {
        id: 13,
        name: "排水",
        parent_id: 5
      },
      {
        id: 14,
        name: "通讯",
        parent_id: 5
      },
      {
        id: 15,
        name: "燃气",
        parent_id: 5
      },
      {
        id: 16,
        name: "中学",
        parent_id: 6
      },
      {
        id: 17,
        name: "小学",
        parent_id: 6
      },
      {
        id: 18,
        name: "幼儿园",
        parent_id: 6
      },
      {
        id: 19,
        name: "大中专院校",
        parent_id: 6
      },
      {
        id: 20,
        name: "农贸市场",
        parent_id: 6
      },
      {
        id: 21,
        name: "医院门诊",
        parent_id: 6
      },
      {
        id: 22,
        name: "银行",
        parent_id: 6
      },
      {
        id: 23,
        name: "影剧院",
        parent_id: 6
      },
      {
        id: 24,
        name: "邮政局",
        parent_id: 6
      },
      {
        id: 6,
        name: "汽车站等交通设施",
        parent_id: 4
      },
      {
        id: 7,
        name: "火车站",
        parent_id: 4
      },
      {
        id: 8,
        name: "高铁站",
        parent_id: 4
      },
      {
        id: 9,
        name: "汽车站",
        parent_id: 4
      },
      {
        id: 10,
        name: "高速公路出入口",
        parent_id: 4
      },
      {
        id: 1,
        name: "商服中心",
        parent_id: 1
      },
      {
        id: 2,
        name: "宾馆酒店",
        parent_id: 1
      },
      {
        id: 3,
        name: "专业市场",
        parent_id: 1
      },
      {
        id: 4,
        name: "农（集）贸市场",
        parent_id: 1
      },
      {
        id: 5,
        name: "大型超市",
        parent_id: 1
      }
    ]
  },
  modal: {
    show: false,
    title: "",
    action: "",
    inputValue: ""
  },
  factorParentId: 0,
  loading: false,
  dataLoading: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constant.CHANGE_MODAL:
      let modal = state.get("modal").mergeDeep(action.value);
      return state.set("modal", modal);
    case constant.CHANGE_ITEM:
      return state.set(action.target, action.value);
    case constant.CHANGE_LOADING:
      return state.set("loading", !state.get("loading"));
    case constant.CHANGE_DATA_LOADING:
      return state.set("dataLoading", !state.get("dataLoading"));
    default:
      return state;
  }
};
