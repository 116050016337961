import React from "react";
import { action } from "store/Project";
import { connect } from "react-redux";

import { Input } from "antd";

class BaseInfoStep extends React.Component {
  render() {
    return (
      <div>
        <div className="label">
          <span style={{ color: "red", marginRight: 10 }}>*</span>评分表名称
        </div>
        <div style={{ marginTop: "1em" }}>
          <Input
            style={{ width: "100%" }}
            value={this.props.newTable.get("title")}
            onChange={this.props.changeTableTitle}
          />
        </div>
      </div>
    );
  }
}

const STP = (state) => {
  return {
    newTable: state.getIn(["project", "newTable"])
  };
};

const DTP = (dispatch) => {
  return {
    changeTableTitle: (e) => {
      dispatch(action.changeItem(["newTable", "title"], e.target.value));
    }
  };
};

export default connect(STP, DTP)(BaseInfoStep);
