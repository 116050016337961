import React from "react";
import { connect } from "react-redux";

import bg from "assets/login_bg.svg";

import styled from "styled-components";
import { Typography, Layout, Row, Col, Spin } from "antd";

const StyledMarkLayout = styled.div`
  background: #f0f2f5;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;

  .main {
    padding: 1em;
    background: white;
    min-height: calc(100vh - 142px);
    box-shadow: 0px 0px 2px 1px #bacad9;

    .title {
      text-align: center;
      padding: 10px 10px 0;
    }

    .content {
      margin-top: 1em;

      .description {
        font-size: 14px;
        text-align: left;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 500;
        margin-bottom: 1em;
      }
    }
  }
`;

class MarkLayout extends React.Component {
  render() {
    return (
      <StyledMarkLayout>
        {this.props.pageLoading ? (
          <div
            style={{
              minHeight: "calc(100vh - 142px)",
              textAlign: "center"
            }}
          >
            <Spin
              size="large"
              style={{ position: "absolute", top: "15vh", left: "48%" }}
            />
          </div>
        ) : (
          <Row justify="center">
            <Col
              xxl={8}
              xl={8}
              lg={16}
              md={18}
              sm={20}
              xs={24}
              className="main"
            >
              <Typography.Title className="title" level={3}>
                {this.props.title}
              </Typography.Title>
              <div className="content">
                <div className="description">{this.props.description}</div>
                {this.props.content}
              </div>
            </Col>
          </Row>
        )}

        <Layout.Footer style={{ textAlign: "center", padding:'8px 16px' }}>
          V2.3 © {new Date().getFullYear()}<br />
          广东卓越土地房地产评估咨询有限公司
        </Layout.Footer>
      </StyledMarkLayout>
    );
  }
}

const mapSTP = (state) => {
  return {
    pageLoading: state.getIn(["layout", "pageLoading"])
  };
};

export default connect(mapSTP, null)(MarkLayout);
